@charset "utf-8";
/* ==============================================
トップページ
============================================== */
#talentTop{
  @include props(pt, 80);

  @include mq(md) {
    @include props(pt, 80, 750);
  }

  .talent{
    max-width: 1076px;
    width: 100%;
    padding: 0 20px;
    margin: 0 auto;
    @include props(pt, 55);
    @include props(pb, 45);

    @include mq(md) {
      @include props(pt, 55, 750);
      @include props(pb, 170, 750);
      @include props(pr, 80, 750);
      @include props(pl, 80, 750);
    }

    .talentTit{
      @include fz_vw(40);
      font-weight: 500;
      line-height: 1.2;
      letter-spacing: 8px;
      text-align: center;
      color: #231815;
      @include props(mb, 63);

      .furigana{
        display: block;
        font-family: $Oswald;
        @include fz_vw(24);
        font-weight: 500;
        line-height: 1;
        letter-spacing: 2.4px;
        text-align: center;
        color: #231815;
        text-transform: uppercase;
        @include props(mt, 18);
      }

      @include mq(md) {
        @include fz_vw(42);
        line-height: 1.14;
        letter-spacing: 4.2px;
        @include props(mb, 55, 750);

        .furigana{
          letter-spacing: 1.2px;
          @include props(mt, 20, 750);
        }
      }

    }

    .mainImgArea{
      display: flex;
      justify-content: space-between;
      @include props(mb, 60);
      
      .img{
        max-width: 45.174%;
        width: 100%;

        img{
          width: 100%;
        }
      }

      @include mq(md) {
        display: block;
        @include props(mb, 50, 750);
        
        .img{
          max-width: 100%;
          @include props(mb, 40, 750);
        }
      }
    }

    .talentProfile{
      display: flex;
      flex-wrap: wrap;
      @include props(mb, 25);

      @include mq(md) {
        display: block;
        @include props(mb, 94, 750);
      }
    }
    .talentProfileTit{
      max-width: 17.4%;
      width: 100%;
      font-family: $Oswald;
      @include fz_vw(24);
      font-weight: 500;
      line-height: 1;
      letter-spacing: 1.68px;
      color: #231815;
      border-bottom: solid 1px #231815;
      @include props(pt, 8);
      @include props(pb, 40);
      @include props(mb, 40);

      &.small{
        font-family: $base-font;
        @include fz_vw(20);
        font-weight: bold;
      }

      &.borderNone{
        border-bottom: none;
        @include props(pb, 25);
        @include props(mb, 25);
      }

      &:last-of-type{
        border-bottom: none;
      }

      @include mq(md) {
        max-width: 100%;
        @include fz_vw(32);
        letter-spacing: 1.12px;
        border-bottom: none;
        @include props(pt, 0);
        @include props(pb, 0);
        @include props(mb, 35, 750);

        &.small{
          @include fz_vw(28);
          font-weight: bold;
        }

        &.borderNone{
          @include props(pb, 0);
          @include props(mb, 35, 750);
        }
      }
    }
    .talentProfileCon{
      max-width: 82.6%;
      width: 100%;
      @include fz_vw(18);
      font-weight: 300;
      line-height: 2;
      letter-spacing: 0.36px;
      color: #231815;
      border-bottom: solid 1px #231815;
      word-break: break-word;
      @include props(pb, 40);
      @include props(mb, 40);

      &.borderNone{
        border-bottom: none;
        @include props(pb, 25);
        @include props(mb, 25);
      }

      p{
        @include fz_vw(16);
        letter-spacing: 0.32px;
        @include props(mt, 50);

        &.attentionTxt{
          font-weight: 300;
          line-height: 2;
          letter-spacing: 1.2px;
          color: #444;
          @include props(mt, 20);
        }
      }

      a{
        text-decoration: underline;
      }

      &:last-of-type{
        border-bottom: none;
      }

      @include mq(md) {
        max-width: 100%;
        @include fz_vw(28);
        line-height: 1.86;
        letter-spacing: normal;
        @include props(pb, 50, 750);
        @include props(mb, 50, 750);
  
        &:last-of-type{
          border-bottom: solid 1px #231815;
        }

        &.borderNone{
          @include props(pb, 35, 750);
          @include props(mb, 35, 750);
        }

        p{
          @include fz_vw(26);
          @include props(mt, 60, 750);

          &.attentionTxt{
            @include fz_vw(24);
            letter-spacing: normal;
            font-feature-settings: "palt" 1;
            @include props(mt, 20, 750);
          }
        }
      }

      .profile{
        display: flex;
        flex-wrap: wrap;
        dt{
          max-width: 9.2%;
          width: 100%;
          text-align-last: justify;
  
          @include mq(md) {
            max-width: 20.2%;
          }
        }
        dd{
          max-width: 90.8%;
          width: 100%;
          display: flex;
          align-items: center;
  
          &::before{
            content: "";
            flex-shrink: 0;
            display: block;
            background-color: #231815;
            border-radius: 100%;
            overflow: hidden;
            @include props(w, 10);
            @include props(h, 10);
            @include props(mt, 2);
            @include props(mr, 17);
            @include props(ml, 17);
          }
  
          @include mq(md) {
            max-width: 79.8%;
            align-items: baseline;
  
            &::before{
              position: relative;
              @include props(w, 15, 750);
              @include props(h, 15, 750);
              @include props(b, 2, 750);
              @include props(mt, 0);
              @include props(mr, 12, 750);
              @include props(ml, 12, 750);
            }
          }
        }
      }

      &.flex{
        display: flex;

        @include mq(md) {
          display: block;
        }

        .leftBox{
          @include props(mr, 100);
          dt{
            @include fz_vw(18);
            font-weight: bold;
            line-height: 2;
            letter-spacing: 0.36px;
            color: #231815;

            &:not(:first-of-type){
              @include props(mt, 36);
            }
          }
          dd{
            @include fz_vw(18);
            line-height: 2;
            letter-spacing: 0.36px;
            color: #231815; 
          }

          @include mq(md) {
            @include props(mr, 0);
            dt{
              @include fz_vw(28);
              line-height: 1.86;
              letter-spacing: normal;
  
              &:not(:first-of-type){
                @include props(mt, 60, 750);
              }
            }
            dd{
              @include fz_vw(28);
              line-height: 1.86;
              letter-spacing: normal;
            }
          }
        }
        .rightBox{
          @include props(mr, 0);
          dt{
            @include fz_vw(18);
            font-weight: bold;
            line-height: 2;
            letter-spacing: 0.36px;
            color: #231815;

            &:not(:first-of-type){
              @include props(mt, 36);
            }
          }
          dd{
            @include fz_vw(18);
            line-height: 2;
            letter-spacing: 0.36px;
            color: #231815; 
          }

          @include mq(md) {
            dt{
              @include fz_vw(28);
              line-height: 1.86;
              letter-spacing: normal;
              @include props(mt, 60, 750);
  
              &:not(:first-of-type){
                @include props(mt, 60, 750);
              }
            }
            dd{
              @include fz_vw(28);
              line-height: 1.86;
              letter-spacing: normal;
            }
          }
        }
      }

      .img{
        @include props(mt, 80);
        @include props(mb, 50);

        @include mq(md) {
          @include props(mt, 80, 750);
          @include props(mb, 50, 750);
        }
      }
    }

    .snsList{
      display: flex;
      flex-wrap: wrap;

      .sns{
        @include props(mr, 25);
        @include props(mb, 30);
        img{
          @include props(w, 40);
        }
        a{
          display: flex;
          align-items: center;
          text-decoration: none;
        }
        p{
          @include fz_vw(18);
          font-weight: bold;
          line-height: 1;
          letter-spacing: 0.36px;
          color: #231815;
          margin: 0;
          @include props(ml, 10);
        }
      }
      
      @include mq(md) {
        display: block;

        .sns{
          @include props(mt, 50, 750);
          @include props(mr, 0);
          @include props(mb, 50, 750);
          img{
            @include props(w, 51, 750);
          }
          p{
            @include fz_vw(28);
            font-weight: 300;
            line-height: 1;
            color: #231815;
            @include props(ml, 26, 750);
          }
        }
      }
    }

    .btn02{
      margin: 0 auto;
    }
  }
}