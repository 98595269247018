@charset "utf-8";

/* =====================================================
フッター
===================================================== */
#footer{
  background-color: #fff808;
  @include props(pt, 30);
  @include props(pb, 30);
  @include props(pr, 20);
  @include props(pl, 20);

  @include mq(max) {
    @include props(pr, 75);
    @include props(pl, 75);
  }

  @include mq(md) {
    @include props(pt, 100, 750);
    @include props(pb, 0);
    @include props(pr, 0);
    @include props(pl, 0);
  }

  .footerContents{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    @include mq(md) {
      display: block;
      @include props(pb, 100, 750);
      @include props(pr, 20, 750);
      @include props(pl, 150, 750);
    }

    .leftBox{
      display: flex;
      align-items: flex-end;
      margin: 0 auto;

      @include mq(desk) {
        margin: 0 auto 0 0;
      }
      @include mq(tab) {
        margin: 0 auto 0 0;
      }
      @include mq(md) {
        display: block;
      }
    }
    .rightBox{
      display: flex;
      align-items: flex-end;

      @include mq(md) {
        display: block;
      }
    }
  }

  .logo{
    @include props(w, 222);
    @include props(mr, 40);

    img{
      width: 100%;
    }

    @include mq(desk) {
      @include props(mr, 0);
    }
    @include mq(tab) {
      @include props(mr, 0);
    }

    @include mq(md) {
      @include props(w, 465, 750);
      @include props(mr, 0);
      @include props(mb, 120, 750);
    }
  }

  .navMenuList{
    display: flex;
    align-items: center;

    @include mq(md) {
      display: block;
    }

    .nav{
      font-family: $Oswald;
      @include fz_vw(18);
      font-weight: 500;
      line-height: 1;
      letter-spacing: 0.36px;
      color: #231815;
      @include props(ml, 40);

      @include mq(tab) {
      @include props(ml, 20);
      }

      @include mq(md) {
        @include fz_vw(26);
        letter-spacing: .91px;
        @include props(ml, 0);
        @include props(mb, 54, 750);
      }
      
      a{
        color: inherit;
      }
    }
  }

  .privacyLink{
    font-family: $Oswald;
    @include fz_vw(14);
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0.28px;
    color: #231815;
    @include props(mr, 25);

    @include mq(tab) {
      @include props(mr, 15);
    }

    @include mq(md) {
      @include fz_vw(24);
      letter-spacing: 0.84px;
      @include props(mr, 0);
    }
      
    a{
      color: inherit;
    }
  }

  .copyright{
    display: block;
    font-family: $Helvetica;
    @include fz_vw(16);
    line-height: 1;
    letter-spacing: 0.96px;
    text-align: right;
    color: #231815;

    @include mq(md) {
      @include fz_vw(24);
      letter-spacing: .72px;
      text-align: center;
      background-color: #fff808;
      @include props(pt, 30, 750);
      @include props(pb, 30, 750);
      @include props(mt, 30, 750);
    }
  }
}