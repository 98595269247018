@charset "utf-8";
/* アニメーション関連---------------------- */
.fadeIn {
  $fadeIn: &;
  opacity: 0;
  visibility:hidden;
  transform: translateY(30px);
  transition: opacity 1.4s .5s, transform 1.2s .5s;
  backface-visibility: hidden;
  &.active {
    opacity: 1;
    visibility: visible;
    transform: none;
    #{$fadeIn}_second {
      opacity: 1;
      visibility: visible;
    }
    #{$fadeIn}_third {
      opacity: 1;
      visibility: visible;
      transform: none;
    }
  }
  #{$fadeIn}_second {
    opacity: 0;
    visibility:hidden;
    transition: opacity .8s 1s;
  }
  #{$fadeIn}_third {
    opacity: 0;
    visibility:hidden;
    transform: translateY(30px);
    transition: opacity .8s .7s, transform .8s .7s;
  }
}

@keyframes arrow01 {
  0% {
    stroke-dashoffset: 1000px;
    stroke-dasharray: 1000px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 1000px;
  }
}

@keyframes moveX {
  0% {
    transform: translateX(0) scale(1);
  }

  10% {
    transform: translateX(0) scale(1);
  }

  20% {
    transform: translateX(20px) scale(1);
  }

  90% {
    transform: translateX(20px) scale(1);
  }

  100% {
    transform: translateX(0) scale(1);
  }
}