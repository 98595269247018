@charset "utf-8";

/* ======================================================================
基本設定
===================================================================== */

* {
  box-sizing: border-box;
}

/* レスポンシブチェック
------------------------------------------------------------- */

#responsibleCheck {
  width: 1px;
  height: 0;
  @include mq(md) {
    width: 2px;
  }
}

/* PC/SPで要素を出しわけ
------------------------------------------------------------- */

.pcOnly {
  @include mq(md) {
    display: none !important;
  }
}

.spOnly {
  display: none !important;
  @include mq(md) {
    display: block !important;
  }
}

html {
  font-size: $mainRemFontSize;
}

body {
  position: relative;
  font-family: $base-font;
  font-size: 1.6rem;
  font-weight: normal;
  color: #555;

  &.fixedBody {
    position: fixed;
    z-index: 0;
    width: 100%;
    height: 100%;
  }
}

/* ハイトカラー消去
------------------------------------------------------------- */

.offTouch {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
}

main {
  position: relative;
  display: block;
  width: 100%;
  article {
    section {
      display: block;
      width: 100%;
      position: relative;
    }
  }
}

.maxContainer {
  max-width: 1600px;
  padding: 0 20px;
  margin: auto;

  @include mq(md) {
    @include props(pr, 40, 750);
    @include props(pl, 40, 750);
  }
}

.container {
  max-width: 1306px;
  padding: 0 20px;
  margin: auto;

  @include mq(md) {
    @include props(pr, 40, 750);
    @include props(pl, 40, 750);
  }
}

.minContainer {
  max-width: 1206px;
  padding: 0 20px;
  margin: auto;

  @include mq(md) {
    @include props(pr, 40, 750);
    @include props(pl, 40, 750);
  }
}


a {
  color: #555;
}
/* 共通デザイン
------------------------------------------------------------- */
.linkTxt01{
  @include fz_vw(14);
  font-weight: 300;
  line-height: 1;
  letter-spacing: 0.7px;
  color: #fff;

  @include mq(md) {
    @include fz_vw(26);
    letter-spacing: .65px;
  }

  a{
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
    color: inherit;

    &:hover{
      &::after{
        transform: translateX(5px);
      }
    }

    &::after{
      content: "";
      display: block;
      background-image: url(/images/common/txt_arrow_white.svg);
      background-position: top center;
      background-repeat: no-repeat;
      background-size: contain;
      transition: transform .3s ease-out;
      @include props(w, 42);
      @include props(h, 9);
      @include props(mb, 2);
      @include props(ml, 6);

      @include mq(md) {
        background-image: url(/images/common/txt_arrow_white_sp.svg);
        @include props(w, 34, 750);
        @include props(h, 8, 750);
        @include props(mb, 3, 750);
        @include props(ml, 15, 750);
      }
    }
  }
}

.btn01{
  @include props(w, 229);

  @include mq(tab) {
    @include props(w, 249);
  }

  @include mq(md) {
    @include props(w, 277, 750);
  }

  a{
    display: flex;
    align-items: baseline;
    justify-content: center;
    font-family: $Oswald;
    @include fz_vw(24);
    font-weight: 500;
    line-height: 1;
    letter-spacing: 2.4px;
    color: #295829;
    background-color: #fff;
    border: solid 1px #fff;
    border-radius: 50px;
    transition: all .3s ease-out;
    @include props(pt, 16);
    @include props(pb, 16);

    &:hover{
      color: #fff;
      background-color: #295829;
      &::after{
        background-image: url(/images/common/arrow_white.svg);
        transform: translateX(5px);
      }
    }

    &::after{
      content: "";
      display: block;
      background-image: url(/images/common/arrow_green03.svg);
      background-position: top center;
      background-repeat: no-repeat;
      background-size: contain;
      transition: transform .3s ease-out;
      @include props(w, 40);
      @include props(h, 12);
      @include props(mb, 2);
      @include props(ml, 13);
    }

    @include mq(md) {
      @include fz_vw(32);
      letter-spacing: 1.6px;
      @include props(pt, 20, 750);
      @include props(pb, 20, 750);

      &:hover{
        &::after{
          background-image: url(/images/common/arrow_white_sp.svg);
        }
      }
  
      &::after{
        background-image: url(/images/common/arrow_green03_sp.svg);
        @include props(w, 34, 750);
        @include props(h, 12, 750);
        @include props(mb, 2, 750);
        @include props(ml, 7, 750);
      }
    }
  }
}

.btn02{
  @include props(w, 229);

  @include mq(tab) {
    @include props(w, 249);
  }

  @include mq(md) {
    @include props(w, 277, 750);
  }

  a{
    display: flex;
    align-items: baseline;
    justify-content: center;
    font-family: $Oswald;
    @include fz_vw(24);
    font-weight: 500;
    line-height: 1;
    letter-spacing: 2.4px;
    color: #fff;
    background-color: #231815;
    border: solid 1px #231815;
    border-radius: 50px;
    transition: all .3s ease-out;
    @include props(pt, 16);
    @include props(pb, 16);

    &:hover{
      color: #231815;
      background-color: #fff;
      &::after{
        background-image: url(/images/common/arrow_black.svg);
        transform: translateX(5px);
      }
    }

    &::after{
      content: "";
      display: block;
      background-image: url(/images/common/arrow_white.svg);
      background-position: top center;
      background-repeat: no-repeat;
      background-size: contain;
      transition: transform .3s ease-out;
      @include props(w, 40);
      @include props(h, 12);
      @include props(mb, 2);
      @include props(ml, 13);
    }

    @include mq(md) {
      @include fz_vw(32);
      letter-spacing: 1.6px;
      @include props(pt, 20, 750);
      @include props(pb, 20, 750);

      &:hover{
        &::after{
          background-image: url(/images/common/arrow_black_sp.svg);
        }
      }
  
      &::after{
        background-image: url(/images/common/arrow_white_sp.svg);
        @include props(w, 34, 750);
        @include props(h, 12, 750);
        @include props(mb, 2, 750);
        @include props(ml, 7, 750);
      }
    }
  }
}

.pageTopBox{
  position: absolute;
  @include props(b, 40);
  @include props(r, 70);

  @include mq(md) {
    @include props(b, 120, 750);
    @include props(r, 80, 750);

    &.black{
      @include props(b, 75, 750);
    }
  }

  .pageTop{
    cursor: pointer;
    font-family: $Oswald;
    @include fz_vw(18);
    font-weight: 500;
    line-height: 1;
    letter-spacing: 1.08px;
    text-align: center;
    color: #fff;

    &:hover{
      &::before{
        transform: translateY(-10px);
      }
    }

    &::before{
      content: "";
      display: block;
      background-image: url(/images/common/page_top_arrow.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      margin: 0 auto;
      transition: transform .3s ease-out;
      @include props(w, 45);
      @include props(h, 90);
      @include props(mb, 20);
    }

    &.black{
      color: #231815;

      &::before{
        background-image: url(/images/common/page_top_arrow_black.svg);
      }
    }

    @include mq(md) {
      @include fz_vw(20);
      letter-spacing: .6px;
  
      &::before{
        @include props(w, 52, 750);
        @include props(h, 92, 750);
        @include props(mb, 15, 750);
      }
    }
  }
}

/* アンカーリンクデザイン
------------------------------------------------------------- */
/* 汎用CSS
------------------------------------------------------------- */
/* ページ送り
------------------------------------------------------------- */
/* SNSリンク集
------------------------------------------------------------- */
/* パン屑リスト
------------------------------------------------------------- */
.breadcrumb {
  position: relative;
  z-index: 1;
  width: 100%;
  @include props(pt, 40);
  @include props(pb, 15);
  clear: both;

  @include mq(md) {
    @include props(pt, 30, 750);
    @include props(pb, 20, 750);
  }
  ol {
    @include clearfix;
    position: relative;
    width: 100%;
    word-break: break-all;
    text-align: left;
    line-height: 1;
    color: #231815;
    margin: 0 auto;
    @include mq(md) {
      max-width: initial;
    }
    &>li {
      display: inline;
      font-family: $Oswald;
      @include fz_vw(16);
      font-weight: normal;
      line-height: 1.22;
      letter-spacing: 0.9px;
      color: #231815;
  
      @include mq(md) {
        @include fz_vw(20);
        letter-spacing: 0.5px;
      }

      a {
        @include fz_vw(16);
        font-family: $Oswald;
        font-weight: 500;
        line-height: 1.22;
        letter-spacing: 0.9px;
        color: #231815;

        @include mq(md) {
          @include fz_vw(20);
          letter-spacing: 0.5px;
        }
      }
      &:first-child {
        a {
          text-decoration: none;
        }
      }
      &+li::before {
        @include fz_vw(14);
        @include props(pr, 5);
        @include props(pl, 0);
        color: #231815;
        content: "＞";

        @include mq(md) {
          @include fz_vw(20);
          @include props(pr, 5, 750);
          @include props(pl, 5, 750);
        }
      }
    }
  }
}