@charset "utf-8";

/* =====================================
ヘッダー
===================================== */
#header {
  position: fixed;
  top: 0;
  left: 0;
  max-width: 100%;
  width: 100%;
  z-index: 999;

  .navBtn {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    z-index: 2;
    @include props(pr, 35, 750);
    @include props(w, 90, 750);
    @include props(h, 32, 750);
    cursor: pointer;

    span {
      display: inline-block;
      position: absolute;
      @include props(l, 0);
      @include props(w, 60, 750);
      height: 2px;
      background-color: #231815;
      transition: transform .5s ease-in-out , opacity .5s ease-in-out, background-color .5s .9s ease-in-out;
      &:nth-of-type(1) {
        @include props(w, 30, 750);
        @include props(t, 32, 750);
      }
      &:nth-of-type(2) {
        top: 49%;
        @include props(w, 45, 750);
      }
      &:nth-of-type(3) {
        @include props(b, 30, 750);
      }
    }
    &.isOpen {
      span {
        transition: transform .5s ease-in-out , opacity .5s ease-in-out, background-color .5s ease-in-out;

        &:nth-of-type(1),
        &:nth-of-type(3) {
          top: 50%;
          height: 2px;
          background-color: #231815;
        }
        &:nth-of-type(1) {
          @include props(w, 60, 750);
          transform: rotate(-145deg);
          z-index: 1;
        }
        &:nth-of-type(2) {
          opacity: 0;
          background-color: #231815;
        }
        &:nth-of-type(3) {
          transform: rotate(145deg);
        }
      }
    }
  }

  .headerContents{
    display: flex;
    align-items: center;
    background-color: #fff808;
    @include props(h, 80);
    @include props(pr, 40);
    @include props(pl, 40);

    @include mq(md) {
      position: relative;
      z-index: 1;
      @include props(h, 80, 750);
      @include props(pr, 30, 750);
      @include props(pl, 20, 750);
    }
  }

  .logo{
    @include props(w, 266); 

    @include mq(md) {
      @include props(w, 272, 750); 
    }
  }
  
  .globalNav{
    margin: 0 0 0 auto;
    .content{
      display: flex;
      align-items: center;
    }

    @include mq(md) {
      position: absolute;
      top: 0;
      right: 0;
      max-width: 57.3%;
      width: 100%;
      height: 100vh;
      z-index: 1;
      visibility: hidden;
      transition: visibility .7s .85s;

      &.isShow {
        visibility: visible;
        transition: visibility .7s;
        &::after {
          transition: transform .7s;
          transform: translateX(0);
        }
        .content {
          transition: opacity .8s .8s, transform .7s .8s;
          opacity: 1;
          transform: translateY(0);
        }
      }
      &::after{
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #fff808;
        z-index: -1;
        transform: translateX(100%);
        transition: transform .7s .9s;
      }

      .content{
        display: block;
        width: 100%;
        height: 100%;
        max-height: 100vh;
        overflow-y: scroll;
        opacity: 0;
        transform: translateY(20px);
        transition: opacity .8s, transform .7s;
        @include props(pt, 170, 750);
        @include props(pr, 40, 750);
        @include props(pl, 75, 750);
        @include props(pb, 75, 750);
      }
    }
  }

  .navMenuList{
    display: flex;
    align-items: center;

    @include mq(md) {
      display: block;
    }

    .nav{
      font-family: $Oswald;
      @include fz_vw(18);
      font-weight: 500;
      line-height: 1;
      letter-spacing: 0.36px;
      color: #231815;
      @include props(ml, 40);

      @include mq(md) {
        @include fz_vw(36);
        letter-spacing: 1.26px;
        color: #231815;
        @include props(ml, 0);
        @include props(mb, 80, 750);
      }

      a{
        color: inherit;
      }
    }

    .navChild{
      font-family: $base-font;
      @include fz_vw(30);
      font-weight: 500;
      line-height: 1.6;
      letter-spacing: 1.5px;
      color: #231815;
      @include props(mt, 30, 750);

      &:first-of-type{
        @include props(mt, 35, 750);
      }
    }
  }

  .contactBtn{
    @include props(w, 143);
    @include props(ml, 45);

    @include mq(tab) {
      @include props(w, 163);
    }

    a{
      display: flex;
      align-items: baseline;
      justify-content: center;
      font-family: $Oswald;
      @include fz_vw(16);
      font-weight: 500;
      line-height: 1;
      letter-spacing: 1.6px;
      color: #fff;
      background-color: #231815;
      border: solid 1px #231815;
      border-radius: 50px;
      transition: all .3s ease-out;
      @include props(pt, 9);
      @include props(pb, 9);

      &:hover{
        color: #231815;
        background-color: #fff;
        &::after{
          background-image: url(/images/common/arrow_black.svg);
          transform: translateX(5px);
        }
      }

      &::after{
        content: "";
        display: block;
        background-image: url(/images/common/arrow_white.svg);
        background-position: top center;
        background-repeat: no-repeat;
        background-size: contain;
        transition: transform .3s ease-out;
        @include props(w, 26);
        @include props(h, 8);
        @include props(mb, 2);
        @include props(ml, 3);
      }
    }
  }
}