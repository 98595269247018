@charset "UTF-8";
/* ================================================================================
値の定義
================================================================================ */
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,500,700&display=swap&subset=japanese");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@400;500;700&display=swap");
/* ================================================================================
importファイル
================================================================================ */
/* ================================================================================
VW設定
================================================================================ */
/*PC設定*/
/*tab設定*/
/*SP設定*/
/*フォントサイズ*/
/*余白計算*/
/*余白計算（一括指定）*/
/*Placeholder*/
/* EXTEND------------------------------------- */
/* ===================================
リセット
=================================== */
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  font: inherit;
  background: transparent;
  vertical-align: baseline;
}

html {
  text-size-adjust: 100%;
}

body {
  *font: x-small;
  color: #555;
  font-size: 62.5%;
  font-family: "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-weight: 500;
}

@media screen and (max-width: 767px) {
  body {
    font-size: 100%;
  }
}

html,
body {
  height: 100%;
  line-height: 1.6;
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}

li {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: '';
  content: none;
}

q:before, q:after {
  content: '';
  content: none;
}

legend {
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

fieldset {
  margin: 0 2px;
  border: 1px solid #999;
  padding: .35em .625em .75em;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  outline: none;
  background: transparent;
  text-decoration: none;
  vertical-align: baseline;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a:link {
  color: #000;
  outline: none;
}

a:visited {
  color: #000;
  outline: none;
}

a:hover {
  text-decoration: none;
  outline: none;
}

a:focus {
  text-decoration: none;
}

ins {
  color: #000;
  background: #ff9;
  text-decoration: none;
}

mark {
  color: #000;
  background-color: #ff9;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted inherit;
  cursor: help;
}

table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}

table th,
table td {
  border-collapse: collapse;
  border-spacing: 0;
}

caption,
th {
  text-align: left;
}

hr {
  height: 1px;
  margin: 1em 0;
  padding: 0;
  border: 0;
  display: block;
  overflow: visible;
  border-top: 1px solid #999;
}

img {
  max-width: 100%;
  display: block;
  font-size: 0;
  line-height: 0;
  height: auto;
  vertical-align: bottom;
}

input,
select {
  vertical-align: middle;
}

button,
select {
  text-transform: none;
}

select {
  -moz-appearance: none;
  text-indent: 0.01px;
  text-overflow: '';
}

select::-ms-expand {
  display: none;
}

button {
  margin: 0;
  padding: 0;
  border: none;
  background: initial;
}

input[type='button'],
input[type='submit'],
input[type='search'],
input[type='reset'],
button {
  -webkit-appearance: none;
}

label {
  font-weight: normal;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

input {
  padding: 0;
}

input::-ms-clear, input::-ms-reveal {
  visibility: hidden;
}

[type='checkbox'],
[type='radio'] {
  padding: 0;
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-cancel-button,
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-input-placeholder {
  color: #ccc;
  opacity: 1;
}

:-moz-placeholder {
  color: #ccc;
}

::-moz-placeholder {
  color: #ccc;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #ccc;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

[aria-busy='true'] {
  cursor: progress;
}

[aria-controls] {
  cursor: pointer;
}

[aria-disabled] {
  cursor: default;
}

a,
area,
button,
input,
label,
select,
textarea,
[tabindex] {
  margin: 0;
  padding: 0;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

input,
textarea,
select {
  font-size: inherit;
  font-weight: inherit;
}

[hidden][aria-hidden='false'] {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute;
}

[hidden][aria-hidden='false']:focus {
  clip: auto;
}

strong {
  font-weight: bold;
}

/* =========================================
印刷時の定義
========================================== */
＠media print section {
  display: block;
  page-break-before: always;
}

＠media print pre,
＠media print code {
  page-break-after: always;
}

@page {
  ＠media print {
    margin-top: .4cm;
    margin: .5cm;
  }
}

＠media print p,
＠media print a,
＠media print code,
＠media print pre {
  word-wrap: break-word;
}

＠media print pre a[href]:after {
  content: "";
}

＠media print pre abbr[title]:after {
  content: "";
}

＠media print pre .ir a:after,
＠media print pre a[href^='javascript:']:after,
＠media print pre a[href^='#']:after {
  content: '';
}

＠media print .noPrint {
  display: none !important;
}

＠media print body {
  width: 1280px;
  font-size: 12pt;
  transform: scale(0.8);
  transform-origin: 0 0;
  -webkit-print-color-adjust: exact;
}

＠media print body .continer {
  padding-top: 0;
}

/* ======================================================================
基本設定
===================================================================== */
* {
  box-sizing: border-box;
}

/* レスポンシブチェック
------------------------------------------------------------- */
#responsibleCheck {
  width: 1px;
  height: 0;
}

@media screen and (max-width: 767px) {
  #responsibleCheck {
    width: 2px;
  }
}

/* PC/SPで要素を出しわけ
------------------------------------------------------------- */
@media screen and (max-width: 767px) {
  .pcOnly {
    display: none !important;
  }
}

.spOnly {
  display: none !important;
}

@media screen and (max-width: 767px) {
  .spOnly {
    display: block !important;
  }
}

html {
  font-size: 62.5%;
}

body {
  position: relative;
  font-family: "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-weight: normal;
  color: #555;
}

body.fixedBody {
  position: fixed;
  z-index: 0;
  width: 100%;
  height: 100%;
}

/* ハイトカラー消去
------------------------------------------------------------- */
.offTouch {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
}

main {
  position: relative;
  display: block;
  width: 100%;
}

main article section {
  display: block;
  width: 100%;
  position: relative;
}

.maxContainer {
  max-width: 1600px;
  padding: 0 20px;
  margin: auto;
}

@media screen and (max-width: 767px) {
  .maxContainer {
    padding-right: 5.33333vw;
    padding-left: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .maxContainer {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .maxContainer {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .maxContainer {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .maxContainer {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .maxContainer {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .maxContainer {
    padding-left: 2.92826vw;
  }
}

.container {
  max-width: 1306px;
  padding: 0 20px;
  margin: auto;
}

@media screen and (max-width: 767px) {
  .container {
    padding-right: 5.33333vw;
    padding-left: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .container {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .container {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .container {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .container {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .container {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .container {
    padding-left: 2.92826vw;
  }
}

.minContainer {
  max-width: 1206px;
  padding: 0 20px;
  margin: auto;
}

@media screen and (max-width: 767px) {
  .minContainer {
    padding-right: 5.33333vw;
    padding-left: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .minContainer {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .minContainer {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .minContainer {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .minContainer {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .minContainer {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .minContainer {
    padding-left: 2.92826vw;
  }
}

a {
  color: #555;
}

/* 共通デザイン
------------------------------------------------------------- */
.linkTxt01 {
  font-size: 1.4rem;
  font-size: 0.72917vw;
  font-weight: 300;
  line-height: 1;
  letter-spacing: 0.7px;
  color: #fff;
}

@media screen and (min-width: 1367px) {
  .linkTxt01 {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .linkTxt01 {
    font-size: 1.02489vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .linkTxt01 {
    font-size: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  .linkTxt01 {
    font-size: 1.86667vw;
  }
}

@media screen and (max-width: 767px) {
  .linkTxt01 {
    font-size: 2.6rem;
    font-size: 1.35417vw;
    letter-spacing: .65px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .linkTxt01 {
    font-size: 2.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .linkTxt01 {
    font-size: 1.90337vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .linkTxt01 {
    font-size: 1.90337vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .linkTxt01 {
    font-size: 3.46667vw;
  }
}

.linkTxt01 a {
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  color: inherit;
}

.linkTxt01 a:hover::after {
  transform: translateX(5px);
}

.linkTxt01 a::after {
  content: "";
  display: block;
  background-image: url(/images/common/txt_arrow_white.svg);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: transform .3s ease-out;
  width: 2.1875vw;
  max-width: 42px;
  height: 0.46875vw;
  max-height: 9px;
  margin-bottom: 0.10417vw;
  margin-left: 0.3125vw;
}

@media screen and (min-width: 1367px) {
  .linkTxt01 a::after {
    width: 42px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .linkTxt01 a::after {
    width: 3.07467vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .linkTxt01 a::after {
    width: 3.07467vw;
  }
}

@media screen and (min-width: 1367px) {
  .linkTxt01 a::after {
    height: 9px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .linkTxt01 a::after {
    height: 0.65886vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .linkTxt01 a::after {
    height: 0.65886vw;
  }
}

@media screen and (min-width: 1367px) {
  .linkTxt01 a::after {
    margin-bottom: 2px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .linkTxt01 a::after {
    margin-bottom: 0.14641vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .linkTxt01 a::after {
    margin-bottom: 0.14641vw;
  }
}

@media screen and (min-width: 1367px) {
  .linkTxt01 a::after {
    margin-left: 6px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .linkTxt01 a::after {
    margin-left: 0.43924vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .linkTxt01 a::after {
    margin-left: 0.43924vw;
  }
}

@media screen and (max-width: 767px) {
  .linkTxt01 a::after {
    background-image: url(/images/common/txt_arrow_white_sp.svg);
    width: 4.53333vw;
    max-width: 34px;
    height: 1.06667vw;
    max-height: 8px;
    margin-bottom: 0.4vw;
    margin-left: 2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .linkTxt01 a::after {
    width: 34px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .linkTxt01 a::after {
    width: 2.48902vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .linkTxt01 a::after {
    width: 2.48902vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .linkTxt01 a::after {
    height: 8px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .linkTxt01 a::after {
    height: 0.58565vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .linkTxt01 a::after {
    height: 0.58565vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .linkTxt01 a::after {
    margin-bottom: 3px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .linkTxt01 a::after {
    margin-bottom: 0.21962vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .linkTxt01 a::after {
    margin-bottom: 0.21962vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .linkTxt01 a::after {
    margin-left: 15px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .linkTxt01 a::after {
    margin-left: 1.0981vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .linkTxt01 a::after {
    margin-left: 1.0981vw;
  }
}

.btn01 {
  width: 11.92708vw;
  max-width: 229px;
}

@media screen and (min-width: 1367px) {
  .btn01 {
    width: 229px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .btn01 {
    width: 16.76428vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .btn01 {
    width: 16.76428vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .btn01 {
    width: 12.96875vw;
    max-width: 249px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1367px) {
  .btn01 {
    width: 249px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1025px) and (max-width: 1440px) {
  .btn01 {
    width: 18.2284vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 768px) and (max-width: 1024px) {
  .btn01 {
    width: 18.2284vw;
  }
}

@media screen and (max-width: 767px) {
  .btn01 {
    width: 36.93333vw;
    max-width: 277px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .btn01 {
    width: 277px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .btn01 {
    width: 20.27818vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .btn01 {
    width: 20.27818vw;
  }
}

.btn01 a {
  display: flex;
  align-items: baseline;
  justify-content: center;
  font-family: "Oswald", sans-serif;
  font-size: 2.4rem;
  font-size: 1.25vw;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 2.4px;
  color: #295829;
  background-color: #fff;
  border: solid 1px #fff;
  border-radius: 50px;
  transition: all .3s ease-out;
  padding-top: 0.83333vw;
  padding-bottom: 0.83333vw;
}

@media screen and (min-width: 1367px) {
  .btn01 a {
    font-size: 2.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .btn01 a {
    font-size: 1.75695vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .btn01 a {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) {
  .btn01 a {
    font-size: 3.2vw;
  }
}

@media screen and (min-width: 1367px) {
  .btn01 a {
    padding-top: 16px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .btn01 a {
    padding-top: 1.1713vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .btn01 a {
    padding-top: 1.1713vw;
  }
}

@media screen and (min-width: 1367px) {
  .btn01 a {
    padding-bottom: 16px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .btn01 a {
    padding-bottom: 1.1713vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .btn01 a {
    padding-bottom: 1.1713vw;
  }
}

.btn01 a:hover {
  color: #fff;
  background-color: #295829;
}

.btn01 a:hover::after {
  background-image: url(/images/common/arrow_white.svg);
  transform: translateX(5px);
}

.btn01 a::after {
  content: "";
  display: block;
  background-image: url(/images/common/arrow_green03.svg);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: transform .3s ease-out;
  width: 2.08333vw;
  max-width: 40px;
  height: 0.625vw;
  max-height: 12px;
  margin-bottom: 0.10417vw;
  margin-left: 0.67708vw;
}

@media screen and (min-width: 1367px) {
  .btn01 a::after {
    width: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .btn01 a::after {
    width: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .btn01 a::after {
    width: 2.92826vw;
  }
}

@media screen and (min-width: 1367px) {
  .btn01 a::after {
    height: 12px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .btn01 a::after {
    height: 0.87848vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .btn01 a::after {
    height: 0.87848vw;
  }
}

@media screen and (min-width: 1367px) {
  .btn01 a::after {
    margin-bottom: 2px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .btn01 a::after {
    margin-bottom: 0.14641vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .btn01 a::after {
    margin-bottom: 0.14641vw;
  }
}

@media screen and (min-width: 1367px) {
  .btn01 a::after {
    margin-left: 13px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .btn01 a::after {
    margin-left: 0.95168vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .btn01 a::after {
    margin-left: 0.95168vw;
  }
}

@media screen and (max-width: 767px) {
  .btn01 a {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    letter-spacing: 1.6px;
    padding-top: 2.66667vw;
    padding-bottom: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .btn01 a {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .btn01 a {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .btn01 a {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .btn01 a {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .btn01 a {
    padding-top: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .btn01 a {
    padding-top: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .btn01 a {
    padding-top: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .btn01 a {
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .btn01 a {
    padding-bottom: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .btn01 a {
    padding-bottom: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  .btn01 a:hover::after {
    background-image: url(/images/common/arrow_white_sp.svg);
  }
  .btn01 a::after {
    background-image: url(/images/common/arrow_green03_sp.svg);
    width: 4.53333vw;
    max-width: 34px;
    height: 1.6vw;
    max-height: 12px;
    margin-bottom: 0.26667vw;
    margin-left: 0.93333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .btn01 a::after {
    width: 34px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .btn01 a::after {
    width: 2.48902vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .btn01 a::after {
    width: 2.48902vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .btn01 a::after {
    height: 12px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .btn01 a::after {
    height: 0.87848vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .btn01 a::after {
    height: 0.87848vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .btn01 a::after {
    margin-bottom: 2px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .btn01 a::after {
    margin-bottom: 0.14641vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .btn01 a::after {
    margin-bottom: 0.14641vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .btn01 a::after {
    margin-left: 7px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .btn01 a::after {
    margin-left: 0.51245vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .btn01 a::after {
    margin-left: 0.51245vw;
  }
}

.btn02 {
  width: 11.92708vw;
  max-width: 229px;
}

@media screen and (min-width: 1367px) {
  .btn02 {
    width: 229px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .btn02 {
    width: 16.76428vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .btn02 {
    width: 16.76428vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .btn02 {
    width: 12.96875vw;
    max-width: 249px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1367px) {
  .btn02 {
    width: 249px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1025px) and (max-width: 1440px) {
  .btn02 {
    width: 18.2284vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 768px) and (max-width: 1024px) {
  .btn02 {
    width: 18.2284vw;
  }
}

@media screen and (max-width: 767px) {
  .btn02 {
    width: 36.93333vw;
    max-width: 277px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .btn02 {
    width: 277px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .btn02 {
    width: 20.27818vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .btn02 {
    width: 20.27818vw;
  }
}

.btn02 a {
  display: flex;
  align-items: baseline;
  justify-content: center;
  font-family: "Oswald", sans-serif;
  font-size: 2.4rem;
  font-size: 1.25vw;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 2.4px;
  color: #fff;
  background-color: #231815;
  border: solid 1px #231815;
  border-radius: 50px;
  transition: all .3s ease-out;
  padding-top: 0.83333vw;
  padding-bottom: 0.83333vw;
}

@media screen and (min-width: 1367px) {
  .btn02 a {
    font-size: 2.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .btn02 a {
    font-size: 1.75695vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .btn02 a {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) {
  .btn02 a {
    font-size: 3.2vw;
  }
}

@media screen and (min-width: 1367px) {
  .btn02 a {
    padding-top: 16px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .btn02 a {
    padding-top: 1.1713vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .btn02 a {
    padding-top: 1.1713vw;
  }
}

@media screen and (min-width: 1367px) {
  .btn02 a {
    padding-bottom: 16px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .btn02 a {
    padding-bottom: 1.1713vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .btn02 a {
    padding-bottom: 1.1713vw;
  }
}

.btn02 a:hover {
  color: #231815;
  background-color: #fff;
}

.btn02 a:hover::after {
  background-image: url(/images/common/arrow_black.svg);
  transform: translateX(5px);
}

.btn02 a::after {
  content: "";
  display: block;
  background-image: url(/images/common/arrow_white.svg);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: transform .3s ease-out;
  width: 2.08333vw;
  max-width: 40px;
  height: 0.625vw;
  max-height: 12px;
  margin-bottom: 0.10417vw;
  margin-left: 0.67708vw;
}

@media screen and (min-width: 1367px) {
  .btn02 a::after {
    width: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .btn02 a::after {
    width: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .btn02 a::after {
    width: 2.92826vw;
  }
}

@media screen and (min-width: 1367px) {
  .btn02 a::after {
    height: 12px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .btn02 a::after {
    height: 0.87848vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .btn02 a::after {
    height: 0.87848vw;
  }
}

@media screen and (min-width: 1367px) {
  .btn02 a::after {
    margin-bottom: 2px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .btn02 a::after {
    margin-bottom: 0.14641vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .btn02 a::after {
    margin-bottom: 0.14641vw;
  }
}

@media screen and (min-width: 1367px) {
  .btn02 a::after {
    margin-left: 13px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .btn02 a::after {
    margin-left: 0.95168vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .btn02 a::after {
    margin-left: 0.95168vw;
  }
}

@media screen and (max-width: 767px) {
  .btn02 a {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    letter-spacing: 1.6px;
    padding-top: 2.66667vw;
    padding-bottom: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .btn02 a {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .btn02 a {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .btn02 a {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .btn02 a {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .btn02 a {
    padding-top: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .btn02 a {
    padding-top: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .btn02 a {
    padding-top: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .btn02 a {
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .btn02 a {
    padding-bottom: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .btn02 a {
    padding-bottom: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  .btn02 a:hover::after {
    background-image: url(/images/common/arrow_black_sp.svg);
  }
  .btn02 a::after {
    background-image: url(/images/common/arrow_white_sp.svg);
    width: 4.53333vw;
    max-width: 34px;
    height: 1.6vw;
    max-height: 12px;
    margin-bottom: 0.26667vw;
    margin-left: 0.93333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .btn02 a::after {
    width: 34px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .btn02 a::after {
    width: 2.48902vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .btn02 a::after {
    width: 2.48902vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .btn02 a::after {
    height: 12px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .btn02 a::after {
    height: 0.87848vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .btn02 a::after {
    height: 0.87848vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .btn02 a::after {
    margin-bottom: 2px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .btn02 a::after {
    margin-bottom: 0.14641vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .btn02 a::after {
    margin-bottom: 0.14641vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .btn02 a::after {
    margin-left: 7px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .btn02 a::after {
    margin-left: 0.51245vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .btn02 a::after {
    margin-left: 0.51245vw;
  }
}

.pageTopBox {
  position: absolute;
  bottom: 2.08333vw;
  right: 3.64583vw;
}

@media screen and (min-width: 1367px) {
  .pageTopBox {
    bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .pageTopBox {
    bottom: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .pageTopBox {
    bottom: 2.92826vw;
  }
}

@media screen and (min-width: 1367px) {
  .pageTopBox {
    right: 70px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .pageTopBox {
    right: 5.12445vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .pageTopBox {
    right: 5.12445vw;
  }
}

@media screen and (max-width: 767px) {
  .pageTopBox {
    bottom: 16vw;
    right: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .pageTopBox {
    bottom: 120px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .pageTopBox {
    bottom: 8.78477vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .pageTopBox {
    bottom: 8.78477vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .pageTopBox {
    right: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .pageTopBox {
    right: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .pageTopBox {
    right: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  .pageTopBox.black {
    bottom: 10vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .pageTopBox.black {
    bottom: 75px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .pageTopBox.black {
    bottom: 5.49048vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .pageTopBox.black {
    bottom: 5.49048vw;
  }
}

.pageTopBox .pageTop {
  cursor: pointer;
  font-family: "Oswald", sans-serif;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 1.08px;
  text-align: center;
  color: #fff;
}

@media screen and (min-width: 1367px) {
  .pageTopBox .pageTop {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .pageTopBox .pageTop {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .pageTopBox .pageTop {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  .pageTopBox .pageTop {
    font-size: 2.4vw;
  }
}

.pageTopBox .pageTop:hover::before {
  transform: translateY(-10px);
}

.pageTopBox .pageTop::before {
  content: "";
  display: block;
  background-image: url(/images/common/page_top_arrow.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0 auto;
  transition: transform .3s ease-out;
  width: 2.34375vw;
  max-width: 45px;
  height: 4.6875vw;
  max-height: 90px;
  margin-bottom: 1.04167vw;
}

@media screen and (min-width: 1367px) {
  .pageTopBox .pageTop::before {
    width: 45px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .pageTopBox .pageTop::before {
    width: 3.29429vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .pageTopBox .pageTop::before {
    width: 3.29429vw;
  }
}

@media screen and (min-width: 1367px) {
  .pageTopBox .pageTop::before {
    height: 90px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .pageTopBox .pageTop::before {
    height: 6.58858vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .pageTopBox .pageTop::before {
    height: 6.58858vw;
  }
}

@media screen and (min-width: 1367px) {
  .pageTopBox .pageTop::before {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .pageTopBox .pageTop::before {
    margin-bottom: 1.46413vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .pageTopBox .pageTop::before {
    margin-bottom: 1.46413vw;
  }
}

.pageTopBox .pageTop.black {
  color: #231815;
}

.pageTopBox .pageTop.black::before {
  background-image: url(/images/common/page_top_arrow_black.svg);
}

@media screen and (max-width: 767px) {
  .pageTopBox .pageTop {
    font-size: 2rem;
    font-size: 1.04167vw;
    letter-spacing: .6px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .pageTopBox .pageTop {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .pageTopBox .pageTop {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .pageTopBox .pageTop {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .pageTopBox .pageTop {
    font-size: 2.66667vw;
  }
}

@media screen and (max-width: 767px) {
  .pageTopBox .pageTop::before {
    width: 6.93333vw;
    max-width: 52px;
    height: 12.26667vw;
    max-height: 92px;
    margin-bottom: 2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .pageTopBox .pageTop::before {
    width: 52px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .pageTopBox .pageTop::before {
    width: 3.80673vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .pageTopBox .pageTop::before {
    width: 3.80673vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .pageTopBox .pageTop::before {
    height: 92px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .pageTopBox .pageTop::before {
    height: 6.73499vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .pageTopBox .pageTop::before {
    height: 6.73499vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .pageTopBox .pageTop::before {
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .pageTopBox .pageTop::before {
    margin-bottom: 1.0981vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .pageTopBox .pageTop::before {
    margin-bottom: 1.0981vw;
  }
}

/* アンカーリンクデザイン
------------------------------------------------------------- */
/* 汎用CSS
------------------------------------------------------------- */
/* ページ送り
------------------------------------------------------------- */
/* SNSリンク集
------------------------------------------------------------- */
/* パン屑リスト
------------------------------------------------------------- */
.breadcrumb {
  position: relative;
  z-index: 1;
  width: 100%;
  padding-top: 2.08333vw;
  padding-bottom: 0.78125vw;
  clear: both;
}

@media screen and (min-width: 1367px) {
  .breadcrumb {
    padding-top: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb {
    padding-top: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb {
    padding-top: 2.92826vw;
  }
}

@media screen and (min-width: 1367px) {
  .breadcrumb {
    padding-bottom: 15px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb {
    padding-bottom: 1.0981vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb {
    padding-bottom: 1.0981vw;
  }
}

@media screen and (max-width: 767px) {
  .breadcrumb {
    padding-top: 4vw;
    padding-bottom: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .breadcrumb {
    padding-top: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb {
    padding-top: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb {
    padding-top: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .breadcrumb {
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb {
    padding-bottom: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb {
    padding-bottom: 1.46413vw;
  }
}

.breadcrumb ol {
  position: relative;
  width: 100%;
  word-break: break-all;
  text-align: left;
  line-height: 1;
  color: #231815;
  margin: 0 auto;
}

.breadcrumb ol::after {
  display: table;
  clear: both;
  content: "";
}

@media screen and (max-width: 767px) {
  .breadcrumb ol {
    max-width: initial;
  }
}

.breadcrumb ol > li {
  display: inline;
  font-family: "Oswald", sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: normal;
  line-height: 1.22;
  letter-spacing: 0.9px;
  color: #231815;
}

@media screen and (min-width: 1367px) {
  .breadcrumb ol > li {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb ol > li {
    font-size: 1.1713vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb ol > li {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  .breadcrumb ol > li {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  .breadcrumb ol > li {
    font-size: 2rem;
    font-size: 1.04167vw;
    letter-spacing: 0.5px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .breadcrumb ol > li {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb ol > li {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb ol > li {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .breadcrumb ol > li {
    font-size: 2.66667vw;
  }
}

.breadcrumb ol > li a {
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-family: "Oswald", sans-serif;
  font-weight: 500;
  line-height: 1.22;
  letter-spacing: 0.9px;
  color: #231815;
}

@media screen and (min-width: 1367px) {
  .breadcrumb ol > li a {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb ol > li a {
    font-size: 1.1713vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb ol > li a {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  .breadcrumb ol > li a {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  .breadcrumb ol > li a {
    font-size: 2rem;
    font-size: 1.04167vw;
    letter-spacing: 0.5px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .breadcrumb ol > li a {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb ol > li a {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb ol > li a {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .breadcrumb ol > li a {
    font-size: 2.66667vw;
  }
}

.breadcrumb ol > li:first-child a {
  text-decoration: none;
}

.breadcrumb ol > li + li::before {
  font-size: 1.4rem;
  font-size: 0.72917vw;
  padding-right: 0.26042vw;
  padding-left: 0vw;
  color: #231815;
  content: "＞";
}

@media screen and (min-width: 1367px) {
  .breadcrumb ol > li + li::before {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb ol > li + li::before {
    font-size: 1.02489vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb ol > li + li::before {
    font-size: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  .breadcrumb ol > li + li::before {
    font-size: 1.86667vw;
  }
}

@media screen and (min-width: 1367px) {
  .breadcrumb ol > li + li::before {
    padding-right: 5px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb ol > li + li::before {
    padding-right: 0.36603vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb ol > li + li::before {
    padding-right: 0.36603vw;
  }
}

@media screen and (min-width: 1367px) {
  .breadcrumb ol > li + li::before {
    padding-left: 0px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb ol > li + li::before {
    padding-left: 0vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb ol > li + li::before {
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) {
  .breadcrumb ol > li + li::before {
    font-size: 2rem;
    font-size: 1.04167vw;
    padding-right: 0.66667vw;
    padding-left: 0.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .breadcrumb ol > li + li::before {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb ol > li + li::before {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb ol > li + li::before {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .breadcrumb ol > li + li::before {
    font-size: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .breadcrumb ol > li + li::before {
    padding-right: 5px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb ol > li + li::before {
    padding-right: 0.36603vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb ol > li + li::before {
    padding-right: 0.36603vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .breadcrumb ol > li + li::before {
    padding-left: 5px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb ol > li + li::before {
    padding-left: 0.36603vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb ol > li + li::before {
    padding-left: 0.36603vw;
  }
}

/* =====================================
ヘッダー
===================================== */
#header {
  position: fixed;
  top: 0;
  left: 0;
  max-width: 100%;
  width: 100%;
  z-index: 999;
}

#header .navBtn {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  z-index: 2;
  padding-right: 4.66667vw;
  width: 12vw;
  max-width: 90px;
  height: 4.26667vw;
  max-height: 32px;
  cursor: pointer;
}

@media screen and (min-width: 1367px) {
  #header .navBtn {
    padding-right: 35px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .navBtn {
    padding-right: 2.56223vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .navBtn {
    padding-right: 2.56223vw;
  }
}

@media screen and (min-width: 1367px) {
  #header .navBtn {
    width: 90px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .navBtn {
    width: 6.58858vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .navBtn {
    width: 6.58858vw;
  }
}

@media screen and (min-width: 1367px) {
  #header .navBtn {
    height: 32px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .navBtn {
    height: 2.34261vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .navBtn {
    height: 2.34261vw;
  }
}

#header .navBtn span {
  display: inline-block;
  position: absolute;
  left: 0vw;
  width: 8vw;
  max-width: 60px;
  height: 2px;
  background-color: #231815;
  transition: transform .5s ease-in-out , opacity .5s ease-in-out, background-color .5s .9s ease-in-out;
}

@media screen and (min-width: 1367px) {
  #header .navBtn span {
    left: 0px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .navBtn span {
    left: 0vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .navBtn span {
    left: 0vw;
  }
}

@media screen and (min-width: 1367px) {
  #header .navBtn span {
    width: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .navBtn span {
    width: 4.39239vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .navBtn span {
    width: 4.39239vw;
  }
}

#header .navBtn span:nth-of-type(1) {
  width: 4vw;
  max-width: 30px;
  top: 4.26667vw;
}

@media screen and (min-width: 1367px) {
  #header .navBtn span:nth-of-type(1) {
    width: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .navBtn span:nth-of-type(1) {
    width: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .navBtn span:nth-of-type(1) {
    width: 2.19619vw;
  }
}

@media screen and (min-width: 1367px) {
  #header .navBtn span:nth-of-type(1) {
    top: 32px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .navBtn span:nth-of-type(1) {
    top: 2.34261vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .navBtn span:nth-of-type(1) {
    top: 2.34261vw;
  }
}

#header .navBtn span:nth-of-type(2) {
  top: 49%;
  width: 6vw;
  max-width: 45px;
}

@media screen and (min-width: 1367px) {
  #header .navBtn span:nth-of-type(2) {
    width: 45px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .navBtn span:nth-of-type(2) {
    width: 3.29429vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .navBtn span:nth-of-type(2) {
    width: 3.29429vw;
  }
}

#header .navBtn span:nth-of-type(3) {
  bottom: 4vw;
}

@media screen and (min-width: 1367px) {
  #header .navBtn span:nth-of-type(3) {
    bottom: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .navBtn span:nth-of-type(3) {
    bottom: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .navBtn span:nth-of-type(3) {
    bottom: 2.19619vw;
  }
}

#header .navBtn.isOpen span {
  transition: transform .5s ease-in-out , opacity .5s ease-in-out, background-color .5s ease-in-out;
}

#header .navBtn.isOpen span:nth-of-type(1), #header .navBtn.isOpen span:nth-of-type(3) {
  top: 50%;
  height: 2px;
  background-color: #231815;
}

#header .navBtn.isOpen span:nth-of-type(1) {
  width: 8vw;
  max-width: 60px;
  transform: rotate(-145deg);
  z-index: 1;
}

@media screen and (min-width: 1367px) {
  #header .navBtn.isOpen span:nth-of-type(1) {
    width: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .navBtn.isOpen span:nth-of-type(1) {
    width: 4.39239vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .navBtn.isOpen span:nth-of-type(1) {
    width: 4.39239vw;
  }
}

#header .navBtn.isOpen span:nth-of-type(2) {
  opacity: 0;
  background-color: #231815;
}

#header .navBtn.isOpen span:nth-of-type(3) {
  transform: rotate(145deg);
}

#header .headerContents {
  display: flex;
  align-items: center;
  background-color: #fff808;
  height: 4.16667vw;
  max-height: 80px;
  padding-right: 2.08333vw;
  padding-left: 2.08333vw;
}

@media screen and (min-width: 1367px) {
  #header .headerContents {
    height: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .headerContents {
    height: 5.85652vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .headerContents {
    height: 5.85652vw;
  }
}

@media screen and (min-width: 1367px) {
  #header .headerContents {
    padding-right: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .headerContents {
    padding-right: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .headerContents {
    padding-right: 2.92826vw;
  }
}

@media screen and (min-width: 1367px) {
  #header .headerContents {
    padding-left: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .headerContents {
    padding-left: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .headerContents {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #header .headerContents {
    position: relative;
    z-index: 1;
    height: 10.66667vw;
    max-height: 80px;
    padding-right: 4vw;
    padding-left: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #header .headerContents {
    height: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #header .headerContents {
    height: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #header .headerContents {
    height: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #header .headerContents {
    padding-right: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #header .headerContents {
    padding-right: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #header .headerContents {
    padding-right: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #header .headerContents {
    padding-left: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #header .headerContents {
    padding-left: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #header .headerContents {
    padding-left: 1.46413vw;
  }
}

#header .logo {
  width: 13.85417vw;
  max-width: 266px;
}

@media screen and (min-width: 1367px) {
  #header .logo {
    width: 266px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .logo {
    width: 19.47291vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .logo {
    width: 19.47291vw;
  }
}

@media screen and (max-width: 767px) {
  #header .logo {
    width: 36.26667vw;
    max-width: 272px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #header .logo {
    width: 272px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #header .logo {
    width: 19.91215vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #header .logo {
    width: 19.91215vw;
  }
}

#header .globalNav {
  margin: 0 0 0 auto;
}

#header .globalNav .content {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 767px) {
  #header .globalNav {
    position: absolute;
    top: 0;
    right: 0;
    max-width: 57.3%;
    width: 100%;
    height: 100vh;
    z-index: 1;
    visibility: hidden;
    transition: visibility .7s .85s;
  }
  #header .globalNav.isShow {
    visibility: visible;
    transition: visibility .7s;
  }
  #header .globalNav.isShow::after {
    transition: transform .7s;
    transform: translateX(0);
  }
  #header .globalNav.isShow .content {
    transition: opacity .8s .8s, transform .7s .8s;
    opacity: 1;
    transform: translateY(0);
  }
  #header .globalNav::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff808;
    z-index: -1;
    transform: translateX(100%);
    transition: transform .7s .9s;
  }
  #header .globalNav .content {
    display: block;
    width: 100%;
    height: 100%;
    max-height: 100vh;
    overflow-y: scroll;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity .8s, transform .7s;
    padding-top: 22.66667vw;
    padding-right: 5.33333vw;
    padding-left: 10vw;
    padding-bottom: 10vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #header .globalNav .content {
    padding-top: 170px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #header .globalNav .content {
    padding-top: 12.4451vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #header .globalNav .content {
    padding-top: 12.4451vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #header .globalNav .content {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #header .globalNav .content {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #header .globalNav .content {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #header .globalNav .content {
    padding-left: 75px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #header .globalNav .content {
    padding-left: 5.49048vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #header .globalNav .content {
    padding-left: 5.49048vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #header .globalNav .content {
    padding-bottom: 75px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #header .globalNav .content {
    padding-bottom: 5.49048vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #header .globalNav .content {
    padding-bottom: 5.49048vw;
  }
}

#header .navMenuList {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 767px) {
  #header .navMenuList {
    display: block;
  }
}

#header .navMenuList .nav {
  font-family: "Oswald", sans-serif;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.36px;
  color: #231815;
  margin-left: 2.08333vw;
}

@media screen and (min-width: 1367px) {
  #header .navMenuList .nav {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .navMenuList .nav {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .navMenuList .nav {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #header .navMenuList .nav {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1367px) {
  #header .navMenuList .nav {
    margin-left: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .navMenuList .nav {
    margin-left: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .navMenuList .nav {
    margin-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #header .navMenuList .nav {
    font-size: 3.6rem;
    font-size: 1.875vw;
    letter-spacing: 1.26px;
    color: #231815;
    margin-left: 0vw;
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #header .navMenuList .nav {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #header .navMenuList .nav {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #header .navMenuList .nav {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #header .navMenuList .nav {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #header .navMenuList .nav {
    margin-left: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #header .navMenuList .nav {
    margin-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #header .navMenuList .nav {
    margin-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #header .navMenuList .nav {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #header .navMenuList .nav {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #header .navMenuList .nav {
    margin-bottom: 5.85652vw;
  }
}

#header .navMenuList .nav a {
  color: inherit;
}

#header .navMenuList .navChild {
  font-family: "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 3rem;
  font-size: 1.5625vw;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 1.5px;
  color: #231815;
  margin-top: 4vw;
}

@media screen and (min-width: 1367px) {
  #header .navMenuList .navChild {
    font-size: 3rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .navMenuList .navChild {
    font-size: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .navMenuList .navChild {
    font-size: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #header .navMenuList .navChild {
    font-size: 4vw;
  }
}

@media screen and (min-width: 1367px) {
  #header .navMenuList .navChild {
    margin-top: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .navMenuList .navChild {
    margin-top: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .navMenuList .navChild {
    margin-top: 2.19619vw;
  }
}

#header .navMenuList .navChild:first-of-type {
  margin-top: 4.66667vw;
}

@media screen and (min-width: 1367px) {
  #header .navMenuList .navChild:first-of-type {
    margin-top: 35px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .navMenuList .navChild:first-of-type {
    margin-top: 2.56223vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .navMenuList .navChild:first-of-type {
    margin-top: 2.56223vw;
  }
}

#header .contactBtn {
  width: 7.44792vw;
  max-width: 143px;
  margin-left: 2.34375vw;
}

@media screen and (min-width: 1367px) {
  #header .contactBtn {
    width: 143px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .contactBtn {
    width: 10.46852vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .contactBtn {
    width: 10.46852vw;
  }
}

@media screen and (min-width: 1367px) {
  #header .contactBtn {
    margin-left: 45px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .contactBtn {
    margin-left: 3.29429vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .contactBtn {
    margin-left: 3.29429vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .contactBtn {
    width: 8.48958vw;
    max-width: 163px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1367px) {
  #header .contactBtn {
    width: 163px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1025px) and (max-width: 1440px) {
  #header .contactBtn {
    width: 11.93265vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 768px) and (max-width: 1024px) {
  #header .contactBtn {
    width: 11.93265vw;
  }
}

#header .contactBtn a {
  display: flex;
  align-items: baseline;
  justify-content: center;
  font-family: "Oswald", sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 1.6px;
  color: #fff;
  background-color: #231815;
  border: solid 1px #231815;
  border-radius: 50px;
  transition: all .3s ease-out;
  padding-top: 0.46875vw;
  padding-bottom: 0.46875vw;
}

@media screen and (min-width: 1367px) {
  #header .contactBtn a {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .contactBtn a {
    font-size: 1.1713vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .contactBtn a {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #header .contactBtn a {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1367px) {
  #header .contactBtn a {
    padding-top: 9px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .contactBtn a {
    padding-top: 0.65886vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .contactBtn a {
    padding-top: 0.65886vw;
  }
}

@media screen and (min-width: 1367px) {
  #header .contactBtn a {
    padding-bottom: 9px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .contactBtn a {
    padding-bottom: 0.65886vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .contactBtn a {
    padding-bottom: 0.65886vw;
  }
}

#header .contactBtn a:hover {
  color: #231815;
  background-color: #fff;
}

#header .contactBtn a:hover::after {
  background-image: url(/images/common/arrow_black.svg);
  transform: translateX(5px);
}

#header .contactBtn a::after {
  content: "";
  display: block;
  background-image: url(/images/common/arrow_white.svg);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: transform .3s ease-out;
  width: 1.35417vw;
  max-width: 26px;
  height: 0.41667vw;
  max-height: 8px;
  margin-bottom: 0.10417vw;
  margin-left: 0.15625vw;
}

@media screen and (min-width: 1367px) {
  #header .contactBtn a::after {
    width: 26px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .contactBtn a::after {
    width: 1.90337vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .contactBtn a::after {
    width: 1.90337vw;
  }
}

@media screen and (min-width: 1367px) {
  #header .contactBtn a::after {
    height: 8px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .contactBtn a::after {
    height: 0.58565vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .contactBtn a::after {
    height: 0.58565vw;
  }
}

@media screen and (min-width: 1367px) {
  #header .contactBtn a::after {
    margin-bottom: 2px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .contactBtn a::after {
    margin-bottom: 0.14641vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .contactBtn a::after {
    margin-bottom: 0.14641vw;
  }
}

@media screen and (min-width: 1367px) {
  #header .contactBtn a::after {
    margin-left: 3px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .contactBtn a::after {
    margin-left: 0.21962vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .contactBtn a::after {
    margin-left: 0.21962vw;
  }
}

/* =====================================================
フッター
===================================================== */
#footer {
  background-color: #fff808;
  padding-top: 1.5625vw;
  padding-bottom: 1.5625vw;
  padding-right: 1.04167vw;
  padding-left: 1.04167vw;
}

@media screen and (min-width: 1367px) {
  #footer {
    padding-top: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer {
    padding-top: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer {
    padding-top: 2.19619vw;
  }
}

@media screen and (min-width: 1367px) {
  #footer {
    padding-bottom: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer {
    padding-bottom: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer {
    padding-bottom: 2.19619vw;
  }
}

@media screen and (min-width: 1367px) {
  #footer {
    padding-right: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer {
    padding-right: 1.46413vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer {
    padding-right: 1.46413vw;
  }
}

@media screen and (min-width: 1367px) {
  #footer {
    padding-left: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer {
    padding-left: 1.46413vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer {
    padding-left: 1.46413vw;
  }
}

@media screen and (min-width: 1920px) {
  #footer {
    padding-right: 3.90625vw;
    padding-left: 3.90625vw;
  }
}

@media screen and (min-width: 1920px) and (min-width: 1367px) {
  #footer {
    padding-right: 75px;
  }
}

@media screen and (min-width: 1920px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer {
    padding-right: 5.49048vw;
  }
}

@media screen and (min-width: 1920px) and (min-width: 768px) and (max-width: 1024px) {
  #footer {
    padding-right: 5.49048vw;
  }
}

@media screen and (min-width: 1920px) and (min-width: 1367px) {
  #footer {
    padding-left: 75px;
  }
}

@media screen and (min-width: 1920px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer {
    padding-left: 5.49048vw;
  }
}

@media screen and (min-width: 1920px) and (min-width: 768px) and (max-width: 1024px) {
  #footer {
    padding-left: 5.49048vw;
  }
}

@media screen and (max-width: 767px) {
  #footer {
    padding-top: 13.33333vw;
    padding-bottom: 0vw;
    padding-right: 0vw;
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #footer {
    padding-top: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer {
    padding-top: 7.32064vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer {
    padding-top: 7.32064vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #footer {
    padding-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer {
    padding-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer {
    padding-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #footer {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #footer {
    padding-left: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer {
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer {
    padding-left: 0vw;
  }
}

#footer .footerContents {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

@media screen and (max-width: 767px) {
  #footer .footerContents {
    display: block;
    padding-bottom: 13.33333vw;
    padding-right: 2.66667vw;
    padding-left: 20vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #footer .footerContents {
    padding-bottom: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerContents {
    padding-bottom: 7.32064vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerContents {
    padding-bottom: 7.32064vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #footer .footerContents {
    padding-right: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerContents {
    padding-right: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerContents {
    padding-right: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #footer .footerContents {
    padding-left: 150px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerContents {
    padding-left: 10.98097vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerContents {
    padding-left: 10.98097vw;
  }
}

#footer .footerContents .leftBox {
  display: flex;
  align-items: flex-end;
  margin: 0 auto;
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerContents .leftBox {
    margin: 0 auto 0 0;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerContents .leftBox {
    margin: 0 auto 0 0;
  }
}

@media screen and (max-width: 767px) {
  #footer .footerContents .leftBox {
    display: block;
  }
}

#footer .footerContents .rightBox {
  display: flex;
  align-items: flex-end;
}

@media screen and (max-width: 767px) {
  #footer .footerContents .rightBox {
    display: block;
  }
}

#footer .logo {
  width: 11.5625vw;
  max-width: 222px;
  margin-right: 2.08333vw;
}

@media screen and (min-width: 1367px) {
  #footer .logo {
    width: 222px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .logo {
    width: 16.25183vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .logo {
    width: 16.25183vw;
  }
}

@media screen and (min-width: 1367px) {
  #footer .logo {
    margin-right: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .logo {
    margin-right: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .logo {
    margin-right: 2.92826vw;
  }
}

#footer .logo img {
  width: 100%;
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .logo {
    margin-right: 0vw;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) and (min-width: 1367px) {
  #footer .logo {
    margin-right: 0px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .logo {
    margin-right: 0vw;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .logo {
    margin-right: 0vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .logo {
    margin-right: 0vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1367px) {
  #footer .logo {
    margin-right: 0px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .logo {
    margin-right: 0vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .logo {
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) {
  #footer .logo {
    width: 62vw;
    max-width: 465px;
    margin-right: 0vw;
    margin-bottom: 16vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #footer .logo {
    width: 465px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .logo {
    width: 34.041vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .logo {
    width: 34.041vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #footer .logo {
    margin-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .logo {
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .logo {
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #footer .logo {
    margin-bottom: 120px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .logo {
    margin-bottom: 8.78477vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .logo {
    margin-bottom: 8.78477vw;
  }
}

#footer .navMenuList {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 767px) {
  #footer .navMenuList {
    display: block;
  }
}

#footer .navMenuList .nav {
  font-family: "Oswald", sans-serif;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.36px;
  color: #231815;
  margin-left: 2.08333vw;
}

@media screen and (min-width: 1367px) {
  #footer .navMenuList .nav {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .navMenuList .nav {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .navMenuList .nav {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #footer .navMenuList .nav {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1367px) {
  #footer .navMenuList .nav {
    margin-left: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .navMenuList .nav {
    margin-left: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .navMenuList .nav {
    margin-left: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .navMenuList .nav {
    margin-left: 1.04167vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1367px) {
  #footer .navMenuList .nav {
    margin-left: 20px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .navMenuList .nav {
    margin-left: 1.46413vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .navMenuList .nav {
    margin-left: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #footer .navMenuList .nav {
    font-size: 2.6rem;
    font-size: 1.35417vw;
    letter-spacing: .91px;
    margin-left: 0vw;
    margin-bottom: 7.2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #footer .navMenuList .nav {
    font-size: 2.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .navMenuList .nav {
    font-size: 1.90337vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .navMenuList .nav {
    font-size: 1.90337vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #footer .navMenuList .nav {
    font-size: 3.46667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #footer .navMenuList .nav {
    margin-left: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .navMenuList .nav {
    margin-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .navMenuList .nav {
    margin-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #footer .navMenuList .nav {
    margin-bottom: 54px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .navMenuList .nav {
    margin-bottom: 3.95315vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .navMenuList .nav {
    margin-bottom: 3.95315vw;
  }
}

#footer .navMenuList .nav a {
  color: inherit;
}

#footer .privacyLink {
  font-family: "Oswald", sans-serif;
  font-size: 1.4rem;
  font-size: 0.72917vw;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.28px;
  color: #231815;
  margin-right: 1.30208vw;
}

@media screen and (min-width: 1367px) {
  #footer .privacyLink {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .privacyLink {
    font-size: 1.02489vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .privacyLink {
    font-size: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  #footer .privacyLink {
    font-size: 1.86667vw;
  }
}

@media screen and (min-width: 1367px) {
  #footer .privacyLink {
    margin-right: 25px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .privacyLink {
    margin-right: 1.83016vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .privacyLink {
    margin-right: 1.83016vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .privacyLink {
    margin-right: 0.78125vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1367px) {
  #footer .privacyLink {
    margin-right: 15px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .privacyLink {
    margin-right: 1.0981vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .privacyLink {
    margin-right: 1.0981vw;
  }
}

@media screen and (max-width: 767px) {
  #footer .privacyLink {
    font-size: 2.4rem;
    font-size: 1.25vw;
    letter-spacing: 0.84px;
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #footer .privacyLink {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .privacyLink {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .privacyLink {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #footer .privacyLink {
    font-size: 3.2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #footer .privacyLink {
    margin-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .privacyLink {
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .privacyLink {
    margin-right: 0vw;
  }
}

#footer .privacyLink a {
  color: inherit;
}

#footer .copyright {
  display: block;
  font-family: "Helvetica", "Helvetica Neue", "Arial", sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  line-height: 1;
  letter-spacing: 0.96px;
  text-align: right;
  color: #231815;
}

@media screen and (min-width: 1367px) {
  #footer .copyright {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .copyright {
    font-size: 1.1713vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .copyright {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #footer .copyright {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #footer .copyright {
    font-size: 2.4rem;
    font-size: 1.25vw;
    letter-spacing: .72px;
    text-align: center;
    background-color: #fff808;
    padding-top: 4vw;
    padding-bottom: 4vw;
    margin-top: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #footer .copyright {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .copyright {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .copyright {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #footer .copyright {
    font-size: 3.2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #footer .copyright {
    padding-top: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .copyright {
    padding-top: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .copyright {
    padding-top: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #footer .copyright {
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .copyright {
    padding-bottom: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .copyright {
    padding-bottom: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #footer .copyright {
    margin-top: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .copyright {
    margin-top: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .copyright {
    margin-top: 2.19619vw;
  }
}

/* 見出し装飾--------------------------- */
/* アニメーション関連---------------------- */
.fadeIn {
  opacity: 0;
  visibility: hidden;
  transform: translateY(30px);
  transition: opacity 1.4s .5s, transform 1.2s .5s;
  backface-visibility: hidden;
}

.fadeIn.active {
  opacity: 1;
  visibility: visible;
  transform: none;
}

.fadeIn.active .fadeIn_second {
  opacity: 1;
  visibility: visible;
}

.fadeIn.active .fadeIn_third {
  opacity: 1;
  visibility: visible;
  transform: none;
}

.fadeIn .fadeIn_second {
  opacity: 0;
  visibility: hidden;
  transition: opacity .8s 1s;
}

.fadeIn .fadeIn_third {
  opacity: 0;
  visibility: hidden;
  transform: translateY(30px);
  transition: opacity .8s .7s, transform .8s .7s;
}

@keyframes arrow01 {
  0% {
    stroke-dashoffset: 1000px;
    stroke-dasharray: 1000px;
  }
  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 1000px;
  }
}

@keyframes moveX {
  0% {
    transform: translateX(0) scale(1);
  }
  10% {
    transform: translateX(0) scale(1);
  }
  20% {
    transform: translateX(20px) scale(1);
  }
  90% {
    transform: translateX(20px) scale(1);
  }
  100% {
    transform: translateX(0) scale(1);
  }
}

/* ボタン装飾------------------------------ */
button {
  outline: none;
}

.columnLayout {
  display: flex;
  justify-content: space-between;
}

.columnLayout .col1 {
  flex-basis: 100%;
}

.columnLayout .col2 {
  flex-basis: 50%;
}

.columnLayout .col3 {
  flex-basis: 33%;
}

.columnLayout .col4 {
  flex-basis: 25%;
}

.columnLayout .col5 {
  flex-basis: 20%;
}

.columnLayout .col6 {
  flex-basis: 16%;
}

.columnLayout .col7 {
  flex-basis: 14%;
}

.columnLayout .col8 {
  flex-basis: 12%;
}

#loading {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  width: auto;
  min-width: 100%;
  height: auto;
  min-height: 100%;
  background: #ffffff;
  opacity: 1;
  z-index: 100000;
}

/************************************
トップページ
***********************************/
/* ==============================================
トップページ
============================================== */
#indexTop {
  background-color: #41603a;
  padding-top: 4.16667vw;
}

@media screen and (min-width: 1367px) {
  #indexTop {
    padding-top: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop {
    padding-top: 5.85652vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop {
    padding-top: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop {
    padding-top: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop {
    padding-top: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop {
    padding-top: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop {
    padding-top: 5.85652vw;
  }
}

#indexTop .mainview {
  z-index: 1;
}

#indexTop .mainview .video {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  width: 100%;
  padding-bottom: 56.25%;
  z-index: -1;
  overflow: hidden;
  background-image: url(/images/video/fv_video@2x.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@media screen and (max-width: 767px) {
  #indexTop .mainview .video {
    padding-bottom: 117.334%;
    background-image: url(/images/video/fv_video_sp@2x.png);
  }
}

#indexTop .mainview .video img {
  width: 100%;
}

#indexTop .mainview .video iframe,
#indexTop .mainview .video video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: opacity .3s ease-out;
}

#indexTop .mainview .video iframe[data-ready="true"],
#indexTop .mainview .video video[data-ready="true"] {
  opacity: 1;
}

@media screen and (max-width: 767px) {
  #indexTop .mainview .video iframe,
  #indexTop .mainview .video video {
    width: auto;
    left: 50%;
    transform: translateX(-50%);
  }
}

#indexTop .mainview::after {
  content: "";
  display: block;
  width: 100%;
  padding-bottom: 56.25%;
}

@media screen and (max-width: 767px) {
  #indexTop .mainview::after {
    padding-bottom: 117.334%;
  }
}

#indexTop .talentArea {
  padding-top: 4.79167vw;
  padding-bottom: 5.625vw;
}

@media screen and (min-width: 1367px) {
  #indexTop .talentArea {
    padding-top: 92px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .talentArea {
    padding-top: 6.73499vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .talentArea {
    padding-top: 6.73499vw;
  }
}

@media screen and (min-width: 1367px) {
  #indexTop .talentArea {
    padding-bottom: 108px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .talentArea {
    padding-bottom: 7.9063vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .talentArea {
    padding-bottom: 7.9063vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .talentArea {
    padding-top: 12.66667vw;
    padding-bottom: 13.33333vw;
    padding-right: 5.33333vw;
    padding-left: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .talentArea {
    padding-top: 95px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .talentArea {
    padding-top: 6.95461vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .talentArea {
    padding-top: 6.95461vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .talentArea {
    padding-bottom: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .talentArea {
    padding-bottom: 7.32064vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .talentArea {
    padding-bottom: 7.32064vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .talentArea {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .talentArea {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .talentArea {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .talentArea {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .talentArea {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .talentArea {
    padding-left: 2.92826vw;
  }
}

#indexTop .talentArea .talentAreaLead {
  font-size: 2rem;
  font-size: 1.04167vw;
  font-weight: 300;
  line-height: 2.4;
  letter-spacing: 1px;
  color: #fff;
  margin-bottom: 5.46875vw;
}

@media screen and (min-width: 1367px) {
  #indexTop .talentArea .talentAreaLead {
    font-size: 2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .talentArea .talentAreaLead {
    font-size: 1.46413vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .talentArea .talentAreaLead {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .talentArea .talentAreaLead {
    font-size: 2.66667vw;
  }
}

@media screen and (min-width: 1367px) {
  #indexTop .talentArea .talentAreaLead {
    margin-bottom: 105px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .talentArea .talentAreaLead {
    margin-bottom: 7.68668vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .talentArea .talentAreaLead {
    margin-bottom: 7.68668vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .talentArea .talentAreaLead {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    line-height: 2;
    letter-spacing: .7px;
    margin-bottom: 6.93333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .talentArea .talentAreaLead {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .talentArea .talentAreaLead {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .talentArea .talentAreaLead {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .talentArea .talentAreaLead {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .talentArea .talentAreaLead {
    margin-bottom: 52px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .talentArea .talentAreaLead {
    margin-bottom: 3.80673vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .talentArea .talentAreaLead {
    margin-bottom: 3.80673vw;
  }
}

#indexTop .talentListArea {
  padding-top: 5.46875vw;
}

@media screen and (min-width: 1367px) {
  #indexTop .talentListArea {
    padding-top: 105px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .talentListArea {
    padding-top: 7.68668vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .talentListArea {
    padding-top: 7.68668vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .talentListArea {
    padding-top: 6.93333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .talentListArea {
    padding-top: 52px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .talentListArea {
    padding-top: 3.80673vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .talentListArea {
    padding-top: 3.80673vw;
  }
}

#indexTop .talentListArea .talentListAreaTit {
  font-family: "Oswald", sans-serif;
  font-size: 5rem;
  font-size: 2.60417vw;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 3.5px;
  text-align: center;
  color: #fff;
  margin-bottom: 5.72917vw;
}

@media screen and (min-width: 1367px) {
  #indexTop .talentListArea .talentListAreaTit {
    font-size: 5rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .talentListArea .talentListAreaTit {
    font-size: 3.66032vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .talentListArea .talentListAreaTit {
    font-size: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .talentListArea .talentListAreaTit {
    font-size: 6.66667vw;
  }
}

@media screen and (min-width: 1367px) {
  #indexTop .talentListArea .talentListAreaTit {
    margin-bottom: 110px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .talentListArea .talentListAreaTit {
    margin-bottom: 8.05271vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .talentListArea .talentListAreaTit {
    margin-bottom: 8.05271vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .talentListArea .talentListAreaTit {
    font-size: 5.2rem;
    font-size: 2.70833vw;
    letter-spacing: 1.82px;
    margin-bottom: 11.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .talentListArea .talentListAreaTit {
    font-size: 5.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .talentListArea .talentListAreaTit {
    font-size: 3.80673vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .talentListArea .talentListAreaTit {
    font-size: 3.80673vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .talentListArea .talentListAreaTit {
    font-size: 6.93333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .talentListArea .talentListAreaTit {
    margin-bottom: 85px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .talentListArea .talentListAreaTit {
    margin-bottom: 6.22255vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .talentListArea .talentListAreaTit {
    margin-bottom: 6.22255vw;
  }
}

#indexTop .talentListArea .flex {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  #indexTop .talentListArea .flex {
    display: block;
  }
}

#indexTop .talentListArea .flex .talentCard {
  max-width: 26.067%;
  width: 100%;
}

@media screen and (max-width: 767px) {
  #indexTop .talentListArea .flex .talentCard {
    max-width: 100%;
    margin-bottom: 14vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .talentListArea .flex .talentCard {
    margin-bottom: 105px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .talentListArea .flex .talentCard {
    margin-bottom: 7.68668vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .talentListArea .flex .talentCard {
    margin-bottom: 7.68668vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .talentListArea .flex .talentCard:last-of-type {
    margin-bottom: 0;
  }
}

#indexTop .talentListArea .flex .talentCard a {
  display: block;
  position: relative;
  overflow: hidden;
  backface-visibility: hidden;
  z-index: 1;
}

#indexTop .talentListArea .flex .talentCard a img,
#indexTop .talentListArea .flex .talentCard a .bgImg {
  transition: transform 1s ease-out;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

#indexTop .talentListArea .flex .talentCard a:hover img,
#indexTop .talentListArea .flex .talentCard a:hover .bgImg, #indexTop .talentListArea .flex .talentCard a.isActive img,
#indexTop .talentListArea .flex .talentCard a.isActive .bgImg {
  transform: scale(1.05) rotate(0.001deg);
  backface-visibility: hidden;
}

#indexTop .talentListArea .flex .talentCard a:hover .moreBtn p {
  color: #fff;
  background-color: #295829;
}

#indexTop .talentListArea .flex .talentCard a:hover .moreBtn p::after {
  background-image: url(/images/common/arrow_white.svg);
  transform: translateX(5px);
}

#indexTop .talentListArea .flex .talentCard a:hover .moreBtn p::after:hover {
  background-image: url(/images/common/arrow_white_sp.svg);
}

#indexTop .talentListArea .flex .talentCard .img {
  width: 100%;
  overflow: hidden;
  height: 22.91667vw;
  max-height: 440px;
  margin-bottom: 1.5625vw;
}

@media screen and (min-width: 1367px) {
  #indexTop .talentListArea .flex .talentCard .img {
    height: 440px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .talentListArea .flex .talentCard .img {
    height: 32.21083vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .talentListArea .flex .talentCard .img {
    height: 32.21083vw;
  }
}

@media screen and (min-width: 1367px) {
  #indexTop .talentListArea .flex .talentCard .img {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .talentListArea .flex .talentCard .img {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .talentListArea .flex .talentCard .img {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .talentListArea .flex .talentCard .img {
    height: 104.8vw;
    max-height: 786px;
    margin-bottom: 6vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .talentListArea .flex .talentCard .img {
    height: 786px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .talentListArea .flex .talentCard .img {
    height: 57.54026vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .talentListArea .flex .talentCard .img {
    height: 57.54026vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .talentListArea .flex .talentCard .img {
    margin-bottom: 45px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .talentListArea .flex .talentCard .img {
    margin-bottom: 3.29429vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .talentListArea .flex .talentCard .img {
    margin-bottom: 3.29429vw;
  }
}

#indexTop .talentListArea .flex .talentCard .img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#indexTop .talentListArea .flex .talentCard .job {
  font-size: 1.6rem;
  font-size: 0.83333vw;
  line-height: 1.5;
  letter-spacing: 1.2px;
  color: #fff;
  margin-bottom: 0.52083vw;
}

@media screen and (min-width: 1367px) {
  #indexTop .talentListArea .flex .talentCard .job {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .talentListArea .flex .talentCard .job {
    font-size: 1.1713vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .talentListArea .flex .talentCard .job {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .talentListArea .flex .talentCard .job {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1367px) {
  #indexTop .talentListArea .flex .talentCard .job {
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .talentListArea .flex .talentCard .job {
    margin-bottom: 0.73206vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .talentListArea .flex .talentCard .job {
    margin-bottom: 0.73206vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .talentListArea .flex .talentCard .job {
    font-size: 2.6rem;
    font-size: 1.35417vw;
    line-height: 1.23;
    letter-spacing: .875px;
    margin-bottom: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .talentListArea .flex .talentCard .job {
    font-size: 2.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .talentListArea .flex .talentCard .job {
    font-size: 1.90337vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .talentListArea .flex .talentCard .job {
    font-size: 1.90337vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .talentListArea .flex .talentCard .job {
    font-size: 3.46667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .talentListArea .flex .talentCard .job {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .talentListArea .flex .talentCard .job {
    margin-bottom: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .talentListArea .flex .talentCard .job {
    margin-bottom: 1.46413vw;
  }
}

#indexTop .talentListArea .flex .talentCard .name {
  font-size: 2.4rem;
  font-size: 1.25vw;
  font-weight: 500;
  line-height: 2;
  letter-spacing: 2.4px;
  color: #fff;
  margin-bottom: 2.86458vw;
}

@media screen and (min-width: 1367px) {
  #indexTop .talentListArea .flex .talentCard .name {
    font-size: 2.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .talentListArea .flex .talentCard .name {
    font-size: 1.75695vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .talentListArea .flex .talentCard .name {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .talentListArea .flex .talentCard .name {
    font-size: 3.2vw;
  }
}

@media screen and (min-width: 1367px) {
  #indexTop .talentListArea .flex .talentCard .name {
    margin-bottom: 55px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .talentListArea .flex .talentCard .name {
    margin-bottom: 4.02635vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .talentListArea .flex .talentCard .name {
    margin-bottom: 4.02635vw;
  }
}

#indexTop .talentListArea .flex .talentCard .name .furigana {
  display: block;
  font-family: "Oswald", sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 1.6px;
  color: #fff;
  text-transform: uppercase;
  margin-top: 0.67708vw;
}

@media screen and (min-width: 1367px) {
  #indexTop .talentListArea .flex .talentCard .name .furigana {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .talentListArea .flex .talentCard .name .furigana {
    font-size: 1.1713vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .talentListArea .flex .talentCard .name .furigana {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .talentListArea .flex .talentCard .name .furigana {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1367px) {
  #indexTop .talentListArea .flex .talentCard .name .furigana {
    margin-top: 13px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .talentListArea .flex .talentCard .name .furigana {
    margin-top: 0.95168vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .talentListArea .flex .talentCard .name .furigana {
    margin-top: 0.95168vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .talentListArea .flex .talentCard .name {
    font-size: 3.6rem;
    font-size: 1.875vw;
    line-height: 1.33;
    letter-spacing: 1.8px;
    margin-bottom: 6vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .talentListArea .flex .talentCard .name {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .talentListArea .flex .talentCard .name {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .talentListArea .flex .talentCard .name {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .talentListArea .flex .talentCard .name {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .talentListArea .flex .talentCard .name {
    margin-bottom: 45px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .talentListArea .flex .talentCard .name {
    margin-bottom: 3.29429vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .talentListArea .flex .talentCard .name {
    margin-bottom: 3.29429vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .talentListArea .flex .talentCard .name .furigana {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    letter-spacing: 1.4px;
    margin-top: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .talentListArea .flex .talentCard .name .furigana {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .talentListArea .flex .talentCard .name .furigana {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .talentListArea .flex .talentCard .name .furigana {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .talentListArea .flex .talentCard .name .furigana {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .talentListArea .flex .talentCard .name .furigana {
    margin-top: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .talentListArea .flex .talentCard .name .furigana {
    margin-top: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .talentListArea .flex .talentCard .name .furigana {
    margin-top: 1.46413vw;
  }
}

#indexTop .talentListArea .flex .talentCard .moreBtn {
  width: 6.35417vw;
  max-width: 122px;
  margin: 0 0 0 auto;
}

@media screen and (min-width: 1367px) {
  #indexTop .talentListArea .flex .talentCard .moreBtn {
    width: 122px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .talentListArea .flex .talentCard .moreBtn {
    width: 8.93119vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .talentListArea .flex .talentCard .moreBtn {
    width: 8.93119vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .talentListArea .flex .talentCard .moreBtn {
    width: 32vw;
    max-width: 240px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .talentListArea .flex .talentCard .moreBtn {
    width: 240px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .talentListArea .flex .talentCard .moreBtn {
    width: 17.56955vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .talentListArea .flex .talentCard .moreBtn {
    width: 17.56955vw;
  }
}

#indexTop .talentListArea .flex .talentCard .moreBtn p {
  display: flex;
  align-items: baseline;
  justify-content: center;
  font-family: "Oswald", sans-serif;
  font-size: 1.7rem;
  font-size: 0.88542vw;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 1.7px;
  color: #295829;
  background-color: #fff;
  border: solid 1px #fff;
  border-radius: 50px;
  transition: all .3s ease-out;
  padding-top: 0.46875vw;
  padding-bottom: 0.46875vw;
}

@media screen and (min-width: 1367px) {
  #indexTop .talentListArea .flex .talentCard .moreBtn p {
    font-size: 1.7rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .talentListArea .flex .talentCard .moreBtn p {
    font-size: 1.24451vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .talentListArea .flex .talentCard .moreBtn p {
    font-size: 1.24451vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .talentListArea .flex .talentCard .moreBtn p {
    font-size: 2.26667vw;
  }
}

@media screen and (min-width: 1367px) {
  #indexTop .talentListArea .flex .talentCard .moreBtn p {
    padding-top: 9px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .talentListArea .flex .talentCard .moreBtn p {
    padding-top: 0.65886vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .talentListArea .flex .talentCard .moreBtn p {
    padding-top: 0.65886vw;
  }
}

@media screen and (min-width: 1367px) {
  #indexTop .talentListArea .flex .talentCard .moreBtn p {
    padding-bottom: 9px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .talentListArea .flex .talentCard .moreBtn p {
    padding-bottom: 0.65886vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .talentListArea .flex .talentCard .moreBtn p {
    padding-bottom: 0.65886vw;
  }
}

#indexTop .talentListArea .flex .talentCard .moreBtn p::after {
  content: "";
  display: block;
  flex-shrink: 0;
  background-image: url(/images/common/arrow_green.svg);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: transform .3s ease-out;
  width: 0.9375vw;
  max-width: 18px;
  height: 0.36458vw;
  max-height: 7px;
  margin-bottom: 0.10417vw;
  margin-left: 0.36458vw;
}

@media screen and (min-width: 1367px) {
  #indexTop .talentListArea .flex .talentCard .moreBtn p::after {
    width: 18px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .talentListArea .flex .talentCard .moreBtn p::after {
    width: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .talentListArea .flex .talentCard .moreBtn p::after {
    width: 1.31772vw;
  }
}

@media screen and (min-width: 1367px) {
  #indexTop .talentListArea .flex .talentCard .moreBtn p::after {
    height: 7px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .talentListArea .flex .talentCard .moreBtn p::after {
    height: 0.51245vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .talentListArea .flex .talentCard .moreBtn p::after {
    height: 0.51245vw;
  }
}

@media screen and (min-width: 1367px) {
  #indexTop .talentListArea .flex .talentCard .moreBtn p::after {
    margin-bottom: 2px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .talentListArea .flex .talentCard .moreBtn p::after {
    margin-bottom: 0.14641vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .talentListArea .flex .talentCard .moreBtn p::after {
    margin-bottom: 0.14641vw;
  }
}

@media screen and (min-width: 1367px) {
  #indexTop .talentListArea .flex .talentCard .moreBtn p::after {
    margin-left: 7px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .talentListArea .flex .talentCard .moreBtn p::after {
    margin-left: 0.51245vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .talentListArea .flex .talentCard .moreBtn p::after {
    margin-left: 0.51245vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .talentListArea .flex .talentCard .moreBtn p {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    padding-top: 2.66667vw;
    padding-bottom: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .talentListArea .flex .talentCard .moreBtn p {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .talentListArea .flex .talentCard .moreBtn p {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .talentListArea .flex .talentCard .moreBtn p {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .talentListArea .flex .talentCard .moreBtn p {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .talentListArea .flex .talentCard .moreBtn p {
    padding-top: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .talentListArea .flex .talentCard .moreBtn p {
    padding-top: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .talentListArea .flex .talentCard .moreBtn p {
    padding-top: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .talentListArea .flex .talentCard .moreBtn p {
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .talentListArea .flex .talentCard .moreBtn p {
    padding-bottom: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .talentListArea .flex .talentCard .moreBtn p {
    padding-bottom: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .talentListArea .flex .talentCard .moreBtn p::after {
    background-image: url(/images/common/arrow_green_sp.svg);
    width: 4.53333vw;
    max-width: 34px;
    height: 1.06667vw;
    max-height: 8px;
    background-position: bottom 1px center;
    margin-bottom: 0.26667vw;
    margin-left: 2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .talentListArea .flex .talentCard .moreBtn p::after {
    width: 34px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .talentListArea .flex .talentCard .moreBtn p::after {
    width: 2.48902vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .talentListArea .flex .talentCard .moreBtn p::after {
    width: 2.48902vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .talentListArea .flex .talentCard .moreBtn p::after {
    height: 8px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .talentListArea .flex .talentCard .moreBtn p::after {
    height: 0.58565vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .talentListArea .flex .talentCard .moreBtn p::after {
    height: 0.58565vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .talentListArea .flex .talentCard .moreBtn p::after {
    margin-bottom: 2px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .talentListArea .flex .talentCard .moreBtn p::after {
    margin-bottom: 0.14641vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .talentListArea .flex .talentCard .moreBtn p::after {
    margin-bottom: 0.14641vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .talentListArea .flex .talentCard .moreBtn p::after {
    margin-left: 15px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .talentListArea .flex .talentCard .moreBtn p::after {
    margin-left: 1.0981vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .talentListArea .flex .talentCard .moreBtn p::after {
    margin-left: 1.0981vw;
  }
}

#indexTop .pickUpArea {
  padding-top: 5.625vw;
  padding-bottom: 4.94792vw;
}

@media screen and (min-width: 1367px) {
  #indexTop .pickUpArea {
    padding-top: 108px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .pickUpArea {
    padding-top: 7.9063vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .pickUpArea {
    padding-top: 7.9063vw;
  }
}

@media screen and (min-width: 1367px) {
  #indexTop .pickUpArea {
    padding-bottom: 95px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .pickUpArea {
    padding-bottom: 6.95461vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .pickUpArea {
    padding-bottom: 6.95461vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .pickUpArea {
    padding-top: 13.33333vw;
    padding-bottom: 13.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .pickUpArea {
    padding-top: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .pickUpArea {
    padding-top: 7.32064vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .pickUpArea {
    padding-top: 7.32064vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .pickUpArea {
    padding-bottom: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .pickUpArea {
    padding-bottom: 7.32064vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .pickUpArea {
    padding-bottom: 7.32064vw;
  }
}

#indexTop .pickUpArea .pickUpAreaTit {
  font-family: "Oswald", sans-serif;
  font-size: 5rem;
  font-size: 2.60417vw;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 3.5px;
  text-align: center;
  color: #fff;
  margin-bottom: 5.72917vw;
}

@media screen and (min-width: 1367px) {
  #indexTop .pickUpArea .pickUpAreaTit {
    font-size: 5rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .pickUpArea .pickUpAreaTit {
    font-size: 3.66032vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .pickUpArea .pickUpAreaTit {
    font-size: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .pickUpArea .pickUpAreaTit {
    font-size: 6.66667vw;
  }
}

@media screen and (min-width: 1367px) {
  #indexTop .pickUpArea .pickUpAreaTit {
    margin-bottom: 110px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .pickUpArea .pickUpAreaTit {
    margin-bottom: 8.05271vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .pickUpArea .pickUpAreaTit {
    margin-bottom: 8.05271vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .pickUpArea .pickUpAreaTit {
    font-size: 5.2rem;
    font-size: 2.70833vw;
    letter-spacing: 1.82px;
    margin-bottom: 12.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .pickUpArea .pickUpAreaTit {
    font-size: 5.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .pickUpArea .pickUpAreaTit {
    font-size: 3.80673vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .pickUpArea .pickUpAreaTit {
    font-size: 3.80673vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .pickUpArea .pickUpAreaTit {
    font-size: 6.93333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .pickUpArea .pickUpAreaTit {
    margin-bottom: 95px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .pickUpArea .pickUpAreaTit {
    margin-bottom: 6.95461vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .pickUpArea .pickUpAreaTit {
    margin-bottom: 6.95461vw;
  }
}

#indexTop .pickUpArea .ps--active-y .ps__thumb-y {
  display: block;
  background-color: #41603a;
  border-radius: 0;
  width: 0.72917vw;
  max-width: 14px;
  right: 0.46875vw;
}

@media screen and (min-width: 1367px) {
  #indexTop .pickUpArea .ps--active-y .ps__thumb-y {
    width: 14px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .pickUpArea .ps--active-y .ps__thumb-y {
    width: 1.02489vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .pickUpArea .ps--active-y .ps__thumb-y {
    width: 1.02489vw;
  }
}

@media screen and (min-width: 1367px) {
  #indexTop .pickUpArea .ps--active-y .ps__thumb-y {
    right: 9px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .pickUpArea .ps--active-y .ps__thumb-y {
    right: 0.65886vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .pickUpArea .ps--active-y .ps__thumb-y {
    right: 0.65886vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .pickUpArea .ps--active-y .ps__thumb-y {
    display: none;
  }
}

#indexTop .pickUpArea .ps--active-y .ps__rail-y {
  display: block;
  width: 1.66667vw;
  max-width: 32px;
  margin-right: 0.72917vw;
  opacity: 1;
  background-color: #95b291;
}

@media screen and (min-width: 1367px) {
  #indexTop .pickUpArea .ps--active-y .ps__rail-y {
    width: 32px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .pickUpArea .ps--active-y .ps__rail-y {
    width: 2.34261vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .pickUpArea .ps--active-y .ps__rail-y {
    width: 2.34261vw;
  }
}

@media screen and (min-width: 1367px) {
  #indexTop .pickUpArea .ps--active-y .ps__rail-y {
    margin-right: 14px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .pickUpArea .ps--active-y .ps__rail-y {
    margin-right: 1.02489vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .pickUpArea .ps--active-y .ps__rail-y {
    margin-right: 1.02489vw;
  }
}

#indexTop .pickUpArea .ps--active-y .ps__rail-y::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0.52083vw;
  max-height: 10px;
  background-color: #95b291;
  z-index: 1;
}

@media screen and (min-width: 1367px) {
  #indexTop .pickUpArea .ps--active-y .ps__rail-y::before {
    height: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .pickUpArea .ps--active-y .ps__rail-y::before {
    height: 0.73206vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .pickUpArea .ps--active-y .ps__rail-y::before {
    height: 0.73206vw;
  }
}

#indexTop .pickUpArea .ps--active-y .ps__rail-y::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.52083vw;
  max-height: 10px;
  background-color: #95b291;
}

@media screen and (min-width: 1367px) {
  #indexTop .pickUpArea .ps--active-y .ps__rail-y::after {
    height: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .pickUpArea .ps--active-y .ps__rail-y::after {
    height: 0.73206vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .pickUpArea .ps--active-y .ps__rail-y::after {
    height: 0.73206vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .pickUpArea .ps--active-y .ps__rail-y {
    display: none;
  }
}

#indexTop .pickUpArea .newsListBox {
  border-radius: 10px;
  background-color: #fff;
  padding-top: 1.66667vw;
  padding-bottom: 1.66667vw;
}

@media screen and (min-width: 1367px) {
  #indexTop .pickUpArea .newsListBox {
    padding-top: 32px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .pickUpArea .newsListBox {
    padding-top: 2.34261vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .pickUpArea .newsListBox {
    padding-top: 2.34261vw;
  }
}

@media screen and (min-width: 1367px) {
  #indexTop .pickUpArea .newsListBox {
    padding-bottom: 32px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .pickUpArea .newsListBox {
    padding-bottom: 2.34261vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .pickUpArea .newsListBox {
    padding-bottom: 2.34261vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .pickUpArea .newsListBox {
    border-radius: 0;
    padding-top: 0vw;
    padding-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .pickUpArea .newsListBox {
    padding-top: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .pickUpArea .newsListBox {
    padding-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .pickUpArea .newsListBox {
    padding-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .pickUpArea .newsListBox {
    padding-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .pickUpArea .newsListBox {
    padding-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .pickUpArea .newsListBox {
    padding-bottom: 0vw;
  }
}

#indexTop .pickUpArea .newsList {
  position: relative;
  overflow-y: scroll;
  padding-left: 2.60417vw;
  padding-right: 5.98958vw;
  height: 22.70833vw;
  max-height: 436px;
}

@media screen and (min-width: 1367px) {
  #indexTop .pickUpArea .newsList {
    padding-left: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .pickUpArea .newsList {
    padding-left: 3.66032vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .pickUpArea .newsList {
    padding-left: 3.66032vw;
  }
}

@media screen and (min-width: 1367px) {
  #indexTop .pickUpArea .newsList {
    padding-right: 115px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .pickUpArea .newsList {
    padding-right: 8.41874vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .pickUpArea .newsList {
    padding-right: 8.41874vw;
  }
}

@media screen and (min-width: 1367px) {
  #indexTop .pickUpArea .newsList {
    height: 436px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .pickUpArea .newsList {
    height: 31.91801vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .pickUpArea .newsList {
    height: 31.91801vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .pickUpArea .newsList {
    padding-top: 6vw;
    padding-bottom: 4.66667vw;
    padding-left: 5.33333vw;
    padding-right: 5.33333vw;
    height: 85.73333vw;
    max-height: 643px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .pickUpArea .newsList {
    padding-top: 45px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .pickUpArea .newsList {
    padding-top: 3.29429vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .pickUpArea .newsList {
    padding-top: 3.29429vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .pickUpArea .newsList {
    padding-bottom: 35px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .pickUpArea .newsList {
    padding-bottom: 2.56223vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .pickUpArea .newsList {
    padding-bottom: 2.56223vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .pickUpArea .newsList {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .pickUpArea .newsList {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .pickUpArea .newsList {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .pickUpArea .newsList {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .pickUpArea .newsList {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .pickUpArea .newsList {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .pickUpArea .newsList {
    height: 643px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .pickUpArea .newsList {
    height: 47.07174vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .pickUpArea .newsList {
    height: 47.07174vw;
  }
}

#indexTop .pickUpArea .newsList .newsListCon a {
  display: flex;
  align-items: center;
  border-bottom: solid 1px #386732;
  transition: opacity .3s ease-out;
  min-height: 5.67708vw;
  padding-top: 0.52083vw;
  padding-bottom: 0.52083vw;
}

@media screen and (min-width: 1367px) {
  #indexTop .pickUpArea .newsList .newsListCon a {
    min-height: 109px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .pickUpArea .newsList .newsListCon a {
    min-height: 7.9795vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .pickUpArea .newsList .newsListCon a {
    min-height: 7.9795vw;
  }
}

@media screen and (min-width: 1367px) {
  #indexTop .pickUpArea .newsList .newsListCon a {
    padding-top: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .pickUpArea .newsList .newsListCon a {
    padding-top: 0.73206vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .pickUpArea .newsList .newsListCon a {
    padding-top: 0.73206vw;
  }
}

@media screen and (min-width: 1367px) {
  #indexTop .pickUpArea .newsList .newsListCon a {
    padding-bottom: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .pickUpArea .newsList .newsListCon a {
    padding-bottom: 0.73206vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .pickUpArea .newsList .newsListCon a {
    padding-bottom: 0.73206vw;
  }
}

#indexTop .pickUpArea .newsList .newsListCon a:hover {
  opacity: .7;
}

#indexTop .pickUpArea .newsList .newsListCon a .newsListTit {
  text-decoration: underline;
}

@media screen and (max-width: 767px) {
  #indexTop .pickUpArea .newsList .newsListCon a {
    flex-flow: column;
    justify-content: center;
    max-height: none;
    height: 100%;
    min-height: 18vw;
    padding-top: 0vw;
    padding-bottom: 2vw;
    margin-bottom: 3.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .pickUpArea .newsList .newsListCon a {
    min-height: 135px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .pickUpArea .newsList .newsListCon a {
    min-height: 9.88287vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .pickUpArea .newsList .newsListCon a {
    min-height: 9.88287vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .pickUpArea .newsList .newsListCon a {
    padding-top: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .pickUpArea .newsList .newsListCon a {
    padding-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .pickUpArea .newsList .newsListCon a {
    padding-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .pickUpArea .newsList .newsListCon a {
    padding-bottom: 15px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .pickUpArea .newsList .newsListCon a {
    padding-bottom: 1.0981vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .pickUpArea .newsList .newsListCon a {
    padding-bottom: 1.0981vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .pickUpArea .newsList .newsListCon a {
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .pickUpArea .newsList .newsListCon a {
    margin-bottom: 1.83016vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .pickUpArea .newsList .newsListCon a {
    margin-bottom: 1.83016vw;
  }
}

#indexTop .pickUpArea .newsList .newsListCon .notLink {
  display: flex;
  align-items: center;
  border-bottom: solid 1px #386732;
  transition: opacity .3s ease-out;
  min-height: 5.67708vw;
  padding-top: 0.52083vw;
  padding-bottom: 0.52083vw;
}

@media screen and (min-width: 1367px) {
  #indexTop .pickUpArea .newsList .newsListCon .notLink {
    min-height: 109px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .pickUpArea .newsList .newsListCon .notLink {
    min-height: 7.9795vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .pickUpArea .newsList .newsListCon .notLink {
    min-height: 7.9795vw;
  }
}

@media screen and (min-width: 1367px) {
  #indexTop .pickUpArea .newsList .newsListCon .notLink {
    padding-top: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .pickUpArea .newsList .newsListCon .notLink {
    padding-top: 0.73206vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .pickUpArea .newsList .newsListCon .notLink {
    padding-top: 0.73206vw;
  }
}

@media screen and (min-width: 1367px) {
  #indexTop .pickUpArea .newsList .newsListCon .notLink {
    padding-bottom: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .pickUpArea .newsList .newsListCon .notLink {
    padding-bottom: 0.73206vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .pickUpArea .newsList .newsListCon .notLink {
    padding-bottom: 0.73206vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .pickUpArea .newsList .newsListCon .notLink {
    flex-flow: column;
    justify-content: center;
    max-height: none;
    height: 100%;
    min-height: 18vw;
    padding-top: 0vw;
    padding-bottom: 2vw;
    margin-bottom: 3.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .pickUpArea .newsList .newsListCon .notLink {
    min-height: 135px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .pickUpArea .newsList .newsListCon .notLink {
    min-height: 9.88287vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .pickUpArea .newsList .newsListCon .notLink {
    min-height: 9.88287vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .pickUpArea .newsList .newsListCon .notLink {
    padding-top: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .pickUpArea .newsList .newsListCon .notLink {
    padding-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .pickUpArea .newsList .newsListCon .notLink {
    padding-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .pickUpArea .newsList .newsListCon .notLink {
    padding-bottom: 15px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .pickUpArea .newsList .newsListCon .notLink {
    padding-bottom: 1.0981vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .pickUpArea .newsList .newsListCon .notLink {
    padding-bottom: 1.0981vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .pickUpArea .newsList .newsListCon .notLink {
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .pickUpArea .newsList .newsListCon .notLink {
    margin-bottom: 1.83016vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .pickUpArea .newsList .newsListCon .notLink {
    margin-bottom: 1.83016vw;
  }
}

#indexTop .pickUpArea .newsList .newsListCon .newsListTime {
  max-width: 13.287%;
  width: 100%;
  display: block;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 600;
  line-height: 2;
  letter-spacing: 0.8px;
  color: #41603a;
}

@media screen and (min-width: 1367px) {
  #indexTop .pickUpArea .newsList .newsListCon .newsListTime {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .pickUpArea .newsList .newsListCon .newsListTime {
    font-size: 1.1713vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .pickUpArea .newsList .newsListCon .newsListTime {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .pickUpArea .newsList .newsListCon .newsListTime {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .pickUpArea .newsList .newsListCon .newsListTime {
    max-width: 100%;
    font-size: 2.6rem;
    font-size: 1.35417vw;
    line-height: 1.23;
    letter-spacing: .65px;
    margin-bottom: 1.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .pickUpArea .newsList .newsListCon .newsListTime {
    font-size: 2.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .pickUpArea .newsList .newsListCon .newsListTime {
    font-size: 1.90337vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .pickUpArea .newsList .newsListCon .newsListTime {
    font-size: 1.90337vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .pickUpArea .newsList .newsListCon .newsListTime {
    font-size: 3.46667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .pickUpArea .newsList .newsListCon .newsListTime {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .pickUpArea .newsList .newsListCon .newsListTime {
    margin-bottom: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .pickUpArea .newsList .newsListCon .newsListTime {
    margin-bottom: 0.73206vw;
  }
}

#indexTop .pickUpArea .newsList .newsListCon .newsListTit {
  max-width: 86.713%;
  width: 100%;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  line-height: 2;
  letter-spacing: 0.8px;
  color: #41603a;
}

@media screen and (min-width: 1367px) {
  #indexTop .pickUpArea .newsList .newsListCon .newsListTit {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .pickUpArea .newsList .newsListCon .newsListTit {
    font-size: 1.1713vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .pickUpArea .newsList .newsListCon .newsListTit {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .pickUpArea .newsList .newsListCon .newsListTit {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .pickUpArea .newsList .newsListCon .newsListTit {
    max-width: 100%;
    font-size: 2.8rem;
    font-size: 1.45833vw;
    letter-spacing: .7px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .pickUpArea .newsList .newsListCon .newsListTit {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .pickUpArea .newsList .newsListCon .newsListTit {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .pickUpArea .newsList .newsListCon .newsListTit {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .pickUpArea .newsList .newsListCon .newsListTit {
    font-size: 3.73333vw;
  }
}

#indexTop .companyInfoArea {
  padding-top: 4.94792vw;
  padding-bottom: 8.07292vw;
}

@media screen and (min-width: 1367px) {
  #indexTop .companyInfoArea {
    padding-top: 95px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .companyInfoArea {
    padding-top: 6.95461vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .companyInfoArea {
    padding-top: 6.95461vw;
  }
}

@media screen and (min-width: 1367px) {
  #indexTop .companyInfoArea {
    padding-bottom: 155px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .companyInfoArea {
    padding-bottom: 11.347vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .companyInfoArea {
    padding-bottom: 11.347vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .companyInfoArea {
    padding-top: 13.33333vw;
    padding-bottom: 40.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .companyInfoArea {
    padding-top: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .companyInfoArea {
    padding-top: 7.32064vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .companyInfoArea {
    padding-top: 7.32064vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .companyInfoArea {
    padding-bottom: 305px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .companyInfoArea {
    padding-bottom: 22.32796vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .companyInfoArea {
    padding-bottom: 22.32796vw;
  }
}

#indexTop .companyInfoArea .companyInfoAreaTit {
  font-family: "Oswald", sans-serif;
  font-size: 5rem;
  font-size: 2.60417vw;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 3.5px;
  text-align: center;
  color: #fff;
  margin-bottom: 3.90625vw;
}

@media screen and (min-width: 1367px) {
  #indexTop .companyInfoArea .companyInfoAreaTit {
    font-size: 5rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .companyInfoArea .companyInfoAreaTit {
    font-size: 3.66032vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .companyInfoArea .companyInfoAreaTit {
    font-size: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .companyInfoArea .companyInfoAreaTit {
    font-size: 6.66667vw;
  }
}

@media screen and (min-width: 1367px) {
  #indexTop .companyInfoArea .companyInfoAreaTit {
    margin-bottom: 75px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .companyInfoArea .companyInfoAreaTit {
    margin-bottom: 5.49048vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .companyInfoArea .companyInfoAreaTit {
    margin-bottom: 5.49048vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .companyInfoArea .companyInfoAreaTit {
    font-size: 5.2rem;
    font-size: 2.70833vw;
    letter-spacing: 1.82px;
    margin-bottom: 11.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .companyInfoArea .companyInfoAreaTit {
    font-size: 5.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .companyInfoArea .companyInfoAreaTit {
    font-size: 3.80673vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .companyInfoArea .companyInfoAreaTit {
    font-size: 3.80673vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .companyInfoArea .companyInfoAreaTit {
    font-size: 6.93333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .companyInfoArea .companyInfoAreaTit {
    margin-bottom: 85px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .companyInfoArea .companyInfoAreaTit {
    margin-bottom: 6.22255vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .companyInfoArea .companyInfoAreaTit {
    margin-bottom: 6.22255vw;
  }
}

#indexTop .companyInfoArea .companyInfoAreaTxtBox {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: 2.08333vw;
}

@media screen and (min-width: 1367px) {
  #indexTop .companyInfoArea .companyInfoAreaTxtBox {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .companyInfoArea .companyInfoAreaTxtBox {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .companyInfoArea .companyInfoAreaTxtBox {
    margin-bottom: 2.92826vw;
  }
}

#indexTop .companyInfoArea .companyInfoAreaTxtBox .linkTxt01 {
  flex-shrink: 0;
}

@media screen and (max-width: 767px) {
  #indexTop .companyInfoArea .companyInfoAreaTxtBox {
    display: block;
    padding-right: 5.33333vw;
    padding-left: 5.33333vw;
    margin-bottom: 23.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .companyInfoArea .companyInfoAreaTxtBox {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .companyInfoArea .companyInfoAreaTxtBox {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .companyInfoArea .companyInfoAreaTxtBox {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .companyInfoArea .companyInfoAreaTxtBox {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .companyInfoArea .companyInfoAreaTxtBox {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .companyInfoArea .companyInfoAreaTxtBox {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .companyInfoArea .companyInfoAreaTxtBox {
    margin-bottom: 175px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .companyInfoArea .companyInfoAreaTxtBox {
    margin-bottom: 12.81113vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .companyInfoArea .companyInfoAreaTxtBox {
    margin-bottom: 12.81113vw;
  }
}

#indexTop .companyInfoArea .companyInfoAreaTxt {
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 300;
  line-height: 2;
  letter-spacing: 0.8px;
  color: #fff;
}

@media screen and (min-width: 1367px) {
  #indexTop .companyInfoArea .companyInfoAreaTxt {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .companyInfoArea .companyInfoAreaTxt {
    font-size: 1.1713vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .companyInfoArea .companyInfoAreaTxt {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .companyInfoArea .companyInfoAreaTxt {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .companyInfoArea .companyInfoAreaTxt {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    letter-spacing: .7px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .companyInfoArea .companyInfoAreaTxt {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .companyInfoArea .companyInfoAreaTxt {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .companyInfoArea .companyInfoAreaTxt {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .companyInfoArea .companyInfoAreaTxt {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .companyInfoArea .linkTxt01 {
    margin-top: 6.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .companyInfoArea .linkTxt01 {
    margin-top: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .companyInfoArea .linkTxt01 {
    margin-top: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .companyInfoArea .linkTxt01 {
    margin-top: 3.66032vw;
  }
}

#indexTop .companyInfoArea .ShibaEntarteimentArea {
  margin-top: 5.83333vw;
  margin-bottom: 6.25vw;
}

@media screen and (min-width: 1367px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea {
    margin-top: 112px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea {
    margin-top: 8.19912vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea {
    margin-top: 8.19912vw;
  }
}

@media screen and (min-width: 1367px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea {
    margin-bottom: 120px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea {
    margin-bottom: 8.78477vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea {
    margin-bottom: 8.78477vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea {
    padding-right: 5.33333vw;
    padding-left: 5.33333vw;
    margin-top: 12vw;
    margin-bottom: 14.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea {
    margin-top: 90px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea {
    margin-top: 6.58858vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea {
    margin-top: 6.58858vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea {
    margin-bottom: 110px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea {
    margin-bottom: 8.05271vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea {
    margin-bottom: 8.05271vw;
  }
}

#indexTop .companyInfoArea .ShibaEntarteimentArea .ShibaEntarteimentAreaTit {
  font-size: 2.4rem;
  font-size: 1.25vw;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 1.2px;
  color: #fff;
  margin-bottom: 0.78125vw;
}

@media screen and (min-width: 1367px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea .ShibaEntarteimentAreaTit {
    font-size: 2.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea .ShibaEntarteimentAreaTit {
    font-size: 1.75695vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea .ShibaEntarteimentAreaTit {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea .ShibaEntarteimentAreaTit {
    font-size: 3.2vw;
  }
}

@media screen and (min-width: 1367px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea .ShibaEntarteimentAreaTit {
    margin-bottom: 15px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea .ShibaEntarteimentAreaTit {
    margin-bottom: 1.0981vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea .ShibaEntarteimentAreaTit {
    margin-bottom: 1.0981vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea .ShibaEntarteimentAreaTit {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    line-height: 1.5;
    letter-spacing: .8px;
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea .ShibaEntarteimentAreaTit {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea .ShibaEntarteimentAreaTit {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea .ShibaEntarteimentAreaTit {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea .ShibaEntarteimentAreaTit {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea .ShibaEntarteimentAreaTit {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea .ShibaEntarteimentAreaTit {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea .ShibaEntarteimentAreaTit {
    margin-bottom: 5.85652vw;
  }
}

#indexTop .companyInfoArea .ShibaEntarteimentArea .ShibaEntarteimentAreaTxt {
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 300;
  line-height: 2;
  letter-spacing: 0.8px;
  color: #fff;
  margin-bottom: 1.45833vw;
}

@media screen and (min-width: 1367px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea .ShibaEntarteimentAreaTxt {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea .ShibaEntarteimentAreaTxt {
    font-size: 1.1713vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea .ShibaEntarteimentAreaTxt {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea .ShibaEntarteimentAreaTxt {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1367px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea .ShibaEntarteimentAreaTxt {
    margin-bottom: 28px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea .ShibaEntarteimentAreaTxt {
    margin-bottom: 2.04978vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea .ShibaEntarteimentAreaTxt {
    margin-bottom: 2.04978vw;
  }
}

#indexTop .companyInfoArea .ShibaEntarteimentArea .ShibaEntarteimentAreaTxt:last-of-type {
  margin-bottom: 0;
}

@media screen and (max-width: 767px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea .ShibaEntarteimentAreaTxt {
    font-size: 2.6rem;
    font-size: 1.35417vw;
    letter-spacing: .65px;
    margin-bottom: 6.93333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea .ShibaEntarteimentAreaTxt {
    font-size: 2.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea .ShibaEntarteimentAreaTxt {
    font-size: 1.90337vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea .ShibaEntarteimentAreaTxt {
    font-size: 1.90337vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea .ShibaEntarteimentAreaTxt {
    font-size: 3.46667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea .ShibaEntarteimentAreaTxt {
    margin-bottom: 52px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea .ShibaEntarteimentAreaTxt {
    margin-bottom: 3.80673vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea .ShibaEntarteimentAreaTxt {
    margin-bottom: 3.80673vw;
  }
}

#indexTop .companyInfoArea .ShibaEntarteimentArea .map {
  position: relative;
  max-width: 100%;
  width: 100%;
  height: 19.01042vw;
  max-height: 365px;
}

@media screen and (min-width: 1367px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea .map {
    height: 365px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea .map {
    height: 26.72035vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea .map {
    height: 26.72035vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea .map {
    height: 52vw;
    max-height: 390px;
    margin-top: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea .map {
    height: 390px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea .map {
    height: 28.55051vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea .map {
    height: 28.55051vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea .map {
    margin-top: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea .map {
    margin-top: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea .map {
    margin-top: 5.85652vw;
  }
}

#indexTop .companyInfoArea .ShibaEntarteimentArea .map iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#indexTop .companyInfoArea .ShibaEntarteimentArea .flex {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4.94792vw;
}

@media screen and (min-width: 1367px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea .flex {
    margin-bottom: 95px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea .flex {
    margin-bottom: 6.95461vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea .flex {
    margin-bottom: 6.95461vw;
  }
}

#indexTop .companyInfoArea .ShibaEntarteimentArea .flex:last-of-type {
  margin-bottom: 0;
}

@media screen and (max-width: 767px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea .flex {
    flex-flow: column;
    margin-bottom: 14.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea .flex {
    margin-bottom: 110px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea .flex {
    margin-bottom: 8.05271vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea .flex {
    margin-bottom: 8.05271vw;
  }
}

#indexTop .companyInfoArea .ShibaEntarteimentArea .flex .leftBox {
  max-width: 47.342%;
  width: 100%;
}

@media screen and (max-width: 767px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea .flex .leftBox {
    max-width: 100%;
    order: 2;
  }
}

#indexTop .companyInfoArea .ShibaEntarteimentArea .flex .rightBox {
  max-width: 52.658%;
  width: 100%;
  padding-left: 3.125vw;
}

@media screen and (min-width: 1367px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea .flex .rightBox {
    padding-left: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea .flex .rightBox {
    padding-left: 4.39239vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea .flex .rightBox {
    padding-left: 4.39239vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea .flex .rightBox {
    max-width: 100%;
    order: 1;
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea .flex .rightBox {
    padding-left: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea .flex .rightBox {
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .companyInfoArea .ShibaEntarteimentArea .flex .rightBox {
    padding-left: 0vw;
  }
}

#indexTop .companyInfoArea .btn01 {
  margin: 0 auto;
}

/* ==============================================
トップページ
============================================== */
#talentTop {
  padding-top: 4.16667vw;
}

@media screen and (min-width: 1367px) {
  #talentTop {
    padding-top: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop {
    padding-top: 5.85652vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #talentTop {
    padding-top: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #talentTop {
    padding-top: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #talentTop {
    padding-top: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop {
    padding-top: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #talentTop {
    padding-top: 5.85652vw;
  }
}

#talentTop .talent {
  max-width: 1076px;
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;
  padding-top: 2.86458vw;
  padding-bottom: 2.34375vw;
}

@media screen and (min-width: 1367px) {
  #talentTop .talent {
    padding-top: 55px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent {
    padding-top: 4.02635vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent {
    padding-top: 4.02635vw;
  }
}

@media screen and (min-width: 1367px) {
  #talentTop .talent {
    padding-bottom: 45px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent {
    padding-bottom: 3.29429vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent {
    padding-bottom: 3.29429vw;
  }
}

@media screen and (max-width: 767px) {
  #talentTop .talent {
    padding-top: 7.33333vw;
    padding-bottom: 22.66667vw;
    padding-right: 10.66667vw;
    padding-left: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #talentTop .talent {
    padding-top: 55px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent {
    padding-top: 4.02635vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent {
    padding-top: 4.02635vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #talentTop .talent {
    padding-bottom: 170px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent {
    padding-bottom: 12.4451vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent {
    padding-bottom: 12.4451vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #talentTop .talent {
    padding-right: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent {
    padding-right: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent {
    padding-right: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #talentTop .talent {
    padding-left: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent {
    padding-left: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent {
    padding-left: 5.85652vw;
  }
}

#talentTop .talent .talentTit {
  font-size: 4rem;
  font-size: 2.08333vw;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 8px;
  text-align: center;
  color: #231815;
  margin-bottom: 3.28125vw;
}

@media screen and (min-width: 1367px) {
  #talentTop .talent .talentTit {
    font-size: 4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentTit {
    font-size: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentTit {
    font-size: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #talentTop .talent .talentTit {
    font-size: 5.33333vw;
  }
}

@media screen and (min-width: 1367px) {
  #talentTop .talent .talentTit {
    margin-bottom: 63px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentTit {
    margin-bottom: 4.61201vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentTit {
    margin-bottom: 4.61201vw;
  }
}

#talentTop .talent .talentTit .furigana {
  display: block;
  font-family: "Oswald", sans-serif;
  font-size: 2.4rem;
  font-size: 1.25vw;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 2.4px;
  text-align: center;
  color: #231815;
  text-transform: uppercase;
  margin-top: 0.9375vw;
}

@media screen and (min-width: 1367px) {
  #talentTop .talent .talentTit .furigana {
    font-size: 2.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentTit .furigana {
    font-size: 1.75695vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentTit .furigana {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) {
  #talentTop .talent .talentTit .furigana {
    font-size: 3.2vw;
  }
}

@media screen and (min-width: 1367px) {
  #talentTop .talent .talentTit .furigana {
    margin-top: 18px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentTit .furigana {
    margin-top: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentTit .furigana {
    margin-top: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #talentTop .talent .talentTit {
    font-size: 4.2rem;
    font-size: 2.1875vw;
    line-height: 1.14;
    letter-spacing: 4.2px;
    margin-bottom: 7.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #talentTop .talent .talentTit {
    font-size: 4.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentTit {
    font-size: 3.07467vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentTit {
    font-size: 3.07467vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #talentTop .talent .talentTit {
    font-size: 5.6vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #talentTop .talent .talentTit {
    margin-bottom: 55px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentTit {
    margin-bottom: 4.02635vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentTit {
    margin-bottom: 4.02635vw;
  }
}

@media screen and (max-width: 767px) {
  #talentTop .talent .talentTit .furigana {
    letter-spacing: 1.2px;
    margin-top: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #talentTop .talent .talentTit .furigana {
    margin-top: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentTit .furigana {
    margin-top: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentTit .furigana {
    margin-top: 1.46413vw;
  }
}

#talentTop .talent .mainImgArea {
  display: flex;
  justify-content: space-between;
  margin-bottom: 3.125vw;
}

@media screen and (min-width: 1367px) {
  #talentTop .talent .mainImgArea {
    margin-bottom: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .mainImgArea {
    margin-bottom: 4.39239vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .mainImgArea {
    margin-bottom: 4.39239vw;
  }
}

#talentTop .talent .mainImgArea .img {
  max-width: 45.174%;
  width: 100%;
}

#talentTop .talent .mainImgArea .img img {
  width: 100%;
}

@media screen and (max-width: 767px) {
  #talentTop .talent .mainImgArea {
    display: block;
    margin-bottom: 6.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #talentTop .talent .mainImgArea {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .mainImgArea {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .mainImgArea {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #talentTop .talent .mainImgArea .img {
    max-width: 100%;
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #talentTop .talent .mainImgArea .img {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .mainImgArea .img {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .mainImgArea .img {
    margin-bottom: 2.92826vw;
  }
}

#talentTop .talent .talentProfile {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1.30208vw;
}

@media screen and (min-width: 1367px) {
  #talentTop .talent .talentProfile {
    margin-bottom: 25px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentProfile {
    margin-bottom: 1.83016vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentProfile {
    margin-bottom: 1.83016vw;
  }
}

@media screen and (max-width: 767px) {
  #talentTop .talent .talentProfile {
    display: block;
    margin-bottom: 12.53333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #talentTop .talent .talentProfile {
    margin-bottom: 94px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentProfile {
    margin-bottom: 6.88141vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentProfile {
    margin-bottom: 6.88141vw;
  }
}

#talentTop .talent .talentProfileTit {
  max-width: 17.4%;
  width: 100%;
  font-family: "Oswald", sans-serif;
  font-size: 2.4rem;
  font-size: 1.25vw;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 1.68px;
  color: #231815;
  border-bottom: solid 1px #231815;
  padding-top: 0.41667vw;
  padding-bottom: 2.08333vw;
  margin-bottom: 2.08333vw;
}

@media screen and (min-width: 1367px) {
  #talentTop .talent .talentProfileTit {
    font-size: 2.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentProfileTit {
    font-size: 1.75695vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentProfileTit {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) {
  #talentTop .talent .talentProfileTit {
    font-size: 3.2vw;
  }
}

@media screen and (min-width: 1367px) {
  #talentTop .talent .talentProfileTit {
    padding-top: 8px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentProfileTit {
    padding-top: 0.58565vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentProfileTit {
    padding-top: 0.58565vw;
  }
}

@media screen and (min-width: 1367px) {
  #talentTop .talent .talentProfileTit {
    padding-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentProfileTit {
    padding-bottom: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentProfileTit {
    padding-bottom: 2.92826vw;
  }
}

@media screen and (min-width: 1367px) {
  #talentTop .talent .talentProfileTit {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentProfileTit {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentProfileTit {
    margin-bottom: 2.92826vw;
  }
}

#talentTop .talent .talentProfileTit.small {
  font-family: "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 2rem;
  font-size: 1.04167vw;
  font-weight: bold;
}

@media screen and (min-width: 1367px) {
  #talentTop .talent .talentProfileTit.small {
    font-size: 2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentProfileTit.small {
    font-size: 1.46413vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentProfileTit.small {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #talentTop .talent .talentProfileTit.small {
    font-size: 2.66667vw;
  }
}

#talentTop .talent .talentProfileTit.borderNone {
  border-bottom: none;
  padding-bottom: 1.30208vw;
  margin-bottom: 1.30208vw;
}

@media screen and (min-width: 1367px) {
  #talentTop .talent .talentProfileTit.borderNone {
    padding-bottom: 25px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentProfileTit.borderNone {
    padding-bottom: 1.83016vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentProfileTit.borderNone {
    padding-bottom: 1.83016vw;
  }
}

@media screen and (min-width: 1367px) {
  #talentTop .talent .talentProfileTit.borderNone {
    margin-bottom: 25px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentProfileTit.borderNone {
    margin-bottom: 1.83016vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentProfileTit.borderNone {
    margin-bottom: 1.83016vw;
  }
}

#talentTop .talent .talentProfileTit:last-of-type {
  border-bottom: none;
}

@media screen and (max-width: 767px) {
  #talentTop .talent .talentProfileTit {
    max-width: 100%;
    font-size: 3.2rem;
    font-size: 1.66667vw;
    letter-spacing: 1.12px;
    border-bottom: none;
    padding-top: 0vw;
    padding-bottom: 0vw;
    margin-bottom: 4.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #talentTop .talent .talentProfileTit {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentProfileTit {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentProfileTit {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #talentTop .talent .talentProfileTit {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #talentTop .talent .talentProfileTit {
    padding-top: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentProfileTit {
    padding-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentProfileTit {
    padding-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #talentTop .talent .talentProfileTit {
    padding-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentProfileTit {
    padding-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentProfileTit {
    padding-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #talentTop .talent .talentProfileTit {
    margin-bottom: 35px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentProfileTit {
    margin-bottom: 2.56223vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentProfileTit {
    margin-bottom: 2.56223vw;
  }
}

@media screen and (max-width: 767px) {
  #talentTop .talent .talentProfileTit.small {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    font-weight: bold;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #talentTop .talent .talentProfileTit.small {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentProfileTit.small {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentProfileTit.small {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #talentTop .talent .talentProfileTit.small {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) {
  #talentTop .talent .talentProfileTit.borderNone {
    padding-bottom: 0vw;
    margin-bottom: 4.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #talentTop .talent .talentProfileTit.borderNone {
    padding-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentProfileTit.borderNone {
    padding-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentProfileTit.borderNone {
    padding-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #talentTop .talent .talentProfileTit.borderNone {
    margin-bottom: 35px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentProfileTit.borderNone {
    margin-bottom: 2.56223vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentProfileTit.borderNone {
    margin-bottom: 2.56223vw;
  }
}

#talentTop .talent .talentProfileCon {
  max-width: 82.6%;
  width: 100%;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: 300;
  line-height: 2;
  letter-spacing: 0.36px;
  color: #231815;
  border-bottom: solid 1px #231815;
  word-break: break-word;
  padding-bottom: 2.08333vw;
  margin-bottom: 2.08333vw;
}

@media screen and (min-width: 1367px) {
  #talentTop .talent .talentProfileCon {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentProfileCon {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentProfileCon {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #talentTop .talent .talentProfileCon {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1367px) {
  #talentTop .talent .talentProfileCon {
    padding-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentProfileCon {
    padding-bottom: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentProfileCon {
    padding-bottom: 2.92826vw;
  }
}

@media screen and (min-width: 1367px) {
  #talentTop .talent .talentProfileCon {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentProfileCon {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentProfileCon {
    margin-bottom: 2.92826vw;
  }
}

#talentTop .talent .talentProfileCon.borderNone {
  border-bottom: none;
  padding-bottom: 1.30208vw;
  margin-bottom: 1.30208vw;
}

@media screen and (min-width: 1367px) {
  #talentTop .talent .talentProfileCon.borderNone {
    padding-bottom: 25px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentProfileCon.borderNone {
    padding-bottom: 1.83016vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentProfileCon.borderNone {
    padding-bottom: 1.83016vw;
  }
}

@media screen and (min-width: 1367px) {
  #talentTop .talent .talentProfileCon.borderNone {
    margin-bottom: 25px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentProfileCon.borderNone {
    margin-bottom: 1.83016vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentProfileCon.borderNone {
    margin-bottom: 1.83016vw;
  }
}

#talentTop .talent .talentProfileCon p {
  font-size: 1.6rem;
  font-size: 0.83333vw;
  letter-spacing: 0.32px;
  margin-top: 2.60417vw;
}

@media screen and (min-width: 1367px) {
  #talentTop .talent .talentProfileCon p {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentProfileCon p {
    font-size: 1.1713vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentProfileCon p {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #talentTop .talent .talentProfileCon p {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1367px) {
  #talentTop .talent .talentProfileCon p {
    margin-top: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentProfileCon p {
    margin-top: 3.66032vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentProfileCon p {
    margin-top: 3.66032vw;
  }
}

#talentTop .talent .talentProfileCon p.attentionTxt {
  font-weight: 300;
  line-height: 2;
  letter-spacing: 1.2px;
  color: #444;
  margin-top: 1.04167vw;
}

@media screen and (min-width: 1367px) {
  #talentTop .talent .talentProfileCon p.attentionTxt {
    margin-top: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentProfileCon p.attentionTxt {
    margin-top: 1.46413vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentProfileCon p.attentionTxt {
    margin-top: 1.46413vw;
  }
}

#talentTop .talent .talentProfileCon a {
  text-decoration: underline;
}

#talentTop .talent .talentProfileCon:last-of-type {
  border-bottom: none;
}

@media screen and (max-width: 767px) {
  #talentTop .talent .talentProfileCon {
    max-width: 100%;
    font-size: 2.8rem;
    font-size: 1.45833vw;
    line-height: 1.86;
    letter-spacing: normal;
    padding-bottom: 6.66667vw;
    margin-bottom: 6.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #talentTop .talent .talentProfileCon {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentProfileCon {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentProfileCon {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #talentTop .talent .talentProfileCon {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #talentTop .talent .talentProfileCon {
    padding-bottom: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentProfileCon {
    padding-bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentProfileCon {
    padding-bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #talentTop .talent .talentProfileCon {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentProfileCon {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentProfileCon {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #talentTop .talent .talentProfileCon:last-of-type {
    border-bottom: solid 1px #231815;
  }
  #talentTop .talent .talentProfileCon.borderNone {
    padding-bottom: 4.66667vw;
    margin-bottom: 4.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #talentTop .talent .talentProfileCon.borderNone {
    padding-bottom: 35px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentProfileCon.borderNone {
    padding-bottom: 2.56223vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentProfileCon.borderNone {
    padding-bottom: 2.56223vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #talentTop .talent .talentProfileCon.borderNone {
    margin-bottom: 35px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentProfileCon.borderNone {
    margin-bottom: 2.56223vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentProfileCon.borderNone {
    margin-bottom: 2.56223vw;
  }
}

@media screen and (max-width: 767px) {
  #talentTop .talent .talentProfileCon p {
    font-size: 2.6rem;
    font-size: 1.35417vw;
    margin-top: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #talentTop .talent .talentProfileCon p {
    font-size: 2.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentProfileCon p {
    font-size: 1.90337vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentProfileCon p {
    font-size: 1.90337vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #talentTop .talent .talentProfileCon p {
    font-size: 3.46667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #talentTop .talent .talentProfileCon p {
    margin-top: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentProfileCon p {
    margin-top: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentProfileCon p {
    margin-top: 4.39239vw;
  }
}

@media screen and (max-width: 767px) {
  #talentTop .talent .talentProfileCon p.attentionTxt {
    font-size: 2.4rem;
    font-size: 1.25vw;
    letter-spacing: normal;
    font-feature-settings: "palt" 1;
    margin-top: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #talentTop .talent .talentProfileCon p.attentionTxt {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentProfileCon p.attentionTxt {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentProfileCon p.attentionTxt {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #talentTop .talent .talentProfileCon p.attentionTxt {
    font-size: 3.2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #talentTop .talent .talentProfileCon p.attentionTxt {
    margin-top: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentProfileCon p.attentionTxt {
    margin-top: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentProfileCon p.attentionTxt {
    margin-top: 1.46413vw;
  }
}

#talentTop .talent .talentProfileCon .profile {
  display: flex;
  flex-wrap: wrap;
}

#talentTop .talent .talentProfileCon .profile dt {
  max-width: 9.2%;
  width: 100%;
  text-align-last: justify;
}

@media screen and (max-width: 767px) {
  #talentTop .talent .talentProfileCon .profile dt {
    max-width: 20.2%;
  }
}

#talentTop .talent .talentProfileCon .profile dd {
  max-width: 90.8%;
  width: 100%;
  display: flex;
  align-items: center;
}

#talentTop .talent .talentProfileCon .profile dd::before {
  content: "";
  flex-shrink: 0;
  display: block;
  background-color: #231815;
  border-radius: 100%;
  overflow: hidden;
  width: 0.52083vw;
  max-width: 10px;
  height: 0.52083vw;
  max-height: 10px;
  margin-top: 0.10417vw;
  margin-right: 0.88542vw;
  margin-left: 0.88542vw;
}

@media screen and (min-width: 1367px) {
  #talentTop .talent .talentProfileCon .profile dd::before {
    width: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentProfileCon .profile dd::before {
    width: 0.73206vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentProfileCon .profile dd::before {
    width: 0.73206vw;
  }
}

@media screen and (min-width: 1367px) {
  #talentTop .talent .talentProfileCon .profile dd::before {
    height: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentProfileCon .profile dd::before {
    height: 0.73206vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentProfileCon .profile dd::before {
    height: 0.73206vw;
  }
}

@media screen and (min-width: 1367px) {
  #talentTop .talent .talentProfileCon .profile dd::before {
    margin-top: 2px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentProfileCon .profile dd::before {
    margin-top: 0.14641vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentProfileCon .profile dd::before {
    margin-top: 0.14641vw;
  }
}

@media screen and (min-width: 1367px) {
  #talentTop .talent .talentProfileCon .profile dd::before {
    margin-right: 17px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentProfileCon .profile dd::before {
    margin-right: 1.24451vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentProfileCon .profile dd::before {
    margin-right: 1.24451vw;
  }
}

@media screen and (min-width: 1367px) {
  #talentTop .talent .talentProfileCon .profile dd::before {
    margin-left: 17px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentProfileCon .profile dd::before {
    margin-left: 1.24451vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentProfileCon .profile dd::before {
    margin-left: 1.24451vw;
  }
}

@media screen and (max-width: 767px) {
  #talentTop .talent .talentProfileCon .profile dd {
    max-width: 79.8%;
    align-items: baseline;
  }
  #talentTop .talent .talentProfileCon .profile dd::before {
    position: relative;
    width: 2vw;
    max-width: 15px;
    height: 2vw;
    max-height: 15px;
    bottom: 0.26667vw;
    margin-top: 0vw;
    margin-right: 1.6vw;
    margin-left: 1.6vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #talentTop .talent .talentProfileCon .profile dd::before {
    width: 15px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentProfileCon .profile dd::before {
    width: 1.0981vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentProfileCon .profile dd::before {
    width: 1.0981vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #talentTop .talent .talentProfileCon .profile dd::before {
    height: 15px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentProfileCon .profile dd::before {
    height: 1.0981vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentProfileCon .profile dd::before {
    height: 1.0981vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #talentTop .talent .talentProfileCon .profile dd::before {
    bottom: 2px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentProfileCon .profile dd::before {
    bottom: 0.14641vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentProfileCon .profile dd::before {
    bottom: 0.14641vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #talentTop .talent .talentProfileCon .profile dd::before {
    margin-top: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentProfileCon .profile dd::before {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentProfileCon .profile dd::before {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #talentTop .talent .talentProfileCon .profile dd::before {
    margin-right: 12px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentProfileCon .profile dd::before {
    margin-right: 0.87848vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentProfileCon .profile dd::before {
    margin-right: 0.87848vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #talentTop .talent .talentProfileCon .profile dd::before {
    margin-left: 12px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentProfileCon .profile dd::before {
    margin-left: 0.87848vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentProfileCon .profile dd::before {
    margin-left: 0.87848vw;
  }
}

#talentTop .talent .talentProfileCon.flex {
  display: flex;
}

@media screen and (max-width: 767px) {
  #talentTop .talent .talentProfileCon.flex {
    display: block;
  }
}

#talentTop .talent .talentProfileCon.flex .leftBox {
  margin-right: 5.20833vw;
}

@media screen and (min-width: 1367px) {
  #talentTop .talent .talentProfileCon.flex .leftBox {
    margin-right: 100px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentProfileCon.flex .leftBox {
    margin-right: 7.32064vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentProfileCon.flex .leftBox {
    margin-right: 7.32064vw;
  }
}

#talentTop .talent .talentProfileCon.flex .leftBox dt {
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: bold;
  line-height: 2;
  letter-spacing: 0.36px;
  color: #231815;
}

@media screen and (min-width: 1367px) {
  #talentTop .talent .talentProfileCon.flex .leftBox dt {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentProfileCon.flex .leftBox dt {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentProfileCon.flex .leftBox dt {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #talentTop .talent .talentProfileCon.flex .leftBox dt {
    font-size: 2.4vw;
  }
}

#talentTop .talent .talentProfileCon.flex .leftBox dt:not(:first-of-type) {
  margin-top: 1.875vw;
}

@media screen and (min-width: 1367px) {
  #talentTop .talent .talentProfileCon.flex .leftBox dt:not(:first-of-type) {
    margin-top: 36px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentProfileCon.flex .leftBox dt:not(:first-of-type) {
    margin-top: 2.63543vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentProfileCon.flex .leftBox dt:not(:first-of-type) {
    margin-top: 2.63543vw;
  }
}

#talentTop .talent .talentProfileCon.flex .leftBox dd {
  font-size: 1.8rem;
  font-size: 0.9375vw;
  line-height: 2;
  letter-spacing: 0.36px;
  color: #231815;
}

@media screen and (min-width: 1367px) {
  #talentTop .talent .talentProfileCon.flex .leftBox dd {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentProfileCon.flex .leftBox dd {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentProfileCon.flex .leftBox dd {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #talentTop .talent .talentProfileCon.flex .leftBox dd {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) {
  #talentTop .talent .talentProfileCon.flex .leftBox {
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #talentTop .talent .talentProfileCon.flex .leftBox {
    margin-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentProfileCon.flex .leftBox {
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentProfileCon.flex .leftBox {
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) {
  #talentTop .talent .talentProfileCon.flex .leftBox dt {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    line-height: 1.86;
    letter-spacing: normal;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #talentTop .talent .talentProfileCon.flex .leftBox dt {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentProfileCon.flex .leftBox dt {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentProfileCon.flex .leftBox dt {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #talentTop .talent .talentProfileCon.flex .leftBox dt {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) {
  #talentTop .talent .talentProfileCon.flex .leftBox dt:not(:first-of-type) {
    margin-top: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #talentTop .talent .talentProfileCon.flex .leftBox dt:not(:first-of-type) {
    margin-top: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentProfileCon.flex .leftBox dt:not(:first-of-type) {
    margin-top: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentProfileCon.flex .leftBox dt:not(:first-of-type) {
    margin-top: 4.39239vw;
  }
}

@media screen and (max-width: 767px) {
  #talentTop .talent .talentProfileCon.flex .leftBox dd {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    line-height: 1.86;
    letter-spacing: normal;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #talentTop .talent .talentProfileCon.flex .leftBox dd {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentProfileCon.flex .leftBox dd {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentProfileCon.flex .leftBox dd {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #talentTop .talent .talentProfileCon.flex .leftBox dd {
    font-size: 3.73333vw;
  }
}

#talentTop .talent .talentProfileCon.flex .rightBox {
  margin-right: 0vw;
}

@media screen and (min-width: 1367px) {
  #talentTop .talent .talentProfileCon.flex .rightBox {
    margin-right: 0px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentProfileCon.flex .rightBox {
    margin-right: 0vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentProfileCon.flex .rightBox {
    margin-right: 0vw;
  }
}

#talentTop .talent .talentProfileCon.flex .rightBox dt {
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: bold;
  line-height: 2;
  letter-spacing: 0.36px;
  color: #231815;
}

@media screen and (min-width: 1367px) {
  #talentTop .talent .talentProfileCon.flex .rightBox dt {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentProfileCon.flex .rightBox dt {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentProfileCon.flex .rightBox dt {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #talentTop .talent .talentProfileCon.flex .rightBox dt {
    font-size: 2.4vw;
  }
}

#talentTop .talent .talentProfileCon.flex .rightBox dt:not(:first-of-type) {
  margin-top: 1.875vw;
}

@media screen and (min-width: 1367px) {
  #talentTop .talent .talentProfileCon.flex .rightBox dt:not(:first-of-type) {
    margin-top: 36px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentProfileCon.flex .rightBox dt:not(:first-of-type) {
    margin-top: 2.63543vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentProfileCon.flex .rightBox dt:not(:first-of-type) {
    margin-top: 2.63543vw;
  }
}

#talentTop .talent .talentProfileCon.flex .rightBox dd {
  font-size: 1.8rem;
  font-size: 0.9375vw;
  line-height: 2;
  letter-spacing: 0.36px;
  color: #231815;
}

@media screen and (min-width: 1367px) {
  #talentTop .talent .talentProfileCon.flex .rightBox dd {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentProfileCon.flex .rightBox dd {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentProfileCon.flex .rightBox dd {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #talentTop .talent .talentProfileCon.flex .rightBox dd {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) {
  #talentTop .talent .talentProfileCon.flex .rightBox dt {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    line-height: 1.86;
    letter-spacing: normal;
    margin-top: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #talentTop .talent .talentProfileCon.flex .rightBox dt {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentProfileCon.flex .rightBox dt {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentProfileCon.flex .rightBox dt {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #talentTop .talent .talentProfileCon.flex .rightBox dt {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #talentTop .talent .talentProfileCon.flex .rightBox dt {
    margin-top: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentProfileCon.flex .rightBox dt {
    margin-top: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentProfileCon.flex .rightBox dt {
    margin-top: 4.39239vw;
  }
}

@media screen and (max-width: 767px) {
  #talentTop .talent .talentProfileCon.flex .rightBox dt:not(:first-of-type) {
    margin-top: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #talentTop .talent .talentProfileCon.flex .rightBox dt:not(:first-of-type) {
    margin-top: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentProfileCon.flex .rightBox dt:not(:first-of-type) {
    margin-top: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentProfileCon.flex .rightBox dt:not(:first-of-type) {
    margin-top: 4.39239vw;
  }
}

@media screen and (max-width: 767px) {
  #talentTop .talent .talentProfileCon.flex .rightBox dd {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    line-height: 1.86;
    letter-spacing: normal;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #talentTop .talent .talentProfileCon.flex .rightBox dd {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentProfileCon.flex .rightBox dd {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentProfileCon.flex .rightBox dd {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #talentTop .talent .talentProfileCon.flex .rightBox dd {
    font-size: 3.73333vw;
  }
}

#talentTop .talent .talentProfileCon .img {
  margin-top: 4.16667vw;
  margin-bottom: 2.60417vw;
}

@media screen and (min-width: 1367px) {
  #talentTop .talent .talentProfileCon .img {
    margin-top: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentProfileCon .img {
    margin-top: 5.85652vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentProfileCon .img {
    margin-top: 5.85652vw;
  }
}

@media screen and (min-width: 1367px) {
  #talentTop .talent .talentProfileCon .img {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentProfileCon .img {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentProfileCon .img {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #talentTop .talent .talentProfileCon .img {
    margin-top: 10.66667vw;
    margin-bottom: 6.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #talentTop .talent .talentProfileCon .img {
    margin-top: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentProfileCon .img {
    margin-top: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentProfileCon .img {
    margin-top: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #talentTop .talent .talentProfileCon .img {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .talentProfileCon .img {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .talentProfileCon .img {
    margin-bottom: 3.66032vw;
  }
}

#talentTop .talent .snsList {
  display: flex;
  flex-wrap: wrap;
}

#talentTop .talent .snsList .sns {
  margin-right: 1.30208vw;
  margin-bottom: 1.5625vw;
}

@media screen and (min-width: 1367px) {
  #talentTop .talent .snsList .sns {
    margin-right: 25px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .snsList .sns {
    margin-right: 1.83016vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .snsList .sns {
    margin-right: 1.83016vw;
  }
}

@media screen and (min-width: 1367px) {
  #talentTop .talent .snsList .sns {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .snsList .sns {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .snsList .sns {
    margin-bottom: 2.19619vw;
  }
}

#talentTop .talent .snsList .sns img {
  width: 2.08333vw;
  max-width: 40px;
}

@media screen and (min-width: 1367px) {
  #talentTop .talent .snsList .sns img {
    width: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .snsList .sns img {
    width: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .snsList .sns img {
    width: 2.92826vw;
  }
}

#talentTop .talent .snsList .sns a {
  display: flex;
  align-items: center;
  text-decoration: none;
}

#talentTop .talent .snsList .sns p {
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: bold;
  line-height: 1;
  letter-spacing: 0.36px;
  color: #231815;
  margin: 0;
  margin-left: 0.52083vw;
}

@media screen and (min-width: 1367px) {
  #talentTop .talent .snsList .sns p {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .snsList .sns p {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .snsList .sns p {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #talentTop .talent .snsList .sns p {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1367px) {
  #talentTop .talent .snsList .sns p {
    margin-left: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .snsList .sns p {
    margin-left: 0.73206vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .snsList .sns p {
    margin-left: 0.73206vw;
  }
}

@media screen and (max-width: 767px) {
  #talentTop .talent .snsList {
    display: block;
  }
  #talentTop .talent .snsList .sns {
    margin-top: 6.66667vw;
    margin-right: 0vw;
    margin-bottom: 6.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #talentTop .talent .snsList .sns {
    margin-top: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .snsList .sns {
    margin-top: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .snsList .sns {
    margin-top: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #talentTop .talent .snsList .sns {
    margin-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .snsList .sns {
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .snsList .sns {
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #talentTop .talent .snsList .sns {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .snsList .sns {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .snsList .sns {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #talentTop .talent .snsList .sns img {
    width: 6.8vw;
    max-width: 51px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #talentTop .talent .snsList .sns img {
    width: 51px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .snsList .sns img {
    width: 3.73353vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .snsList .sns img {
    width: 3.73353vw;
  }
}

@media screen and (max-width: 767px) {
  #talentTop .talent .snsList .sns p {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    font-weight: 300;
    line-height: 1;
    color: #231815;
    margin-left: 3.46667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #talentTop .talent .snsList .sns p {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .snsList .sns p {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .snsList .sns p {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #talentTop .talent .snsList .sns p {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  #talentTop .talent .snsList .sns p {
    margin-left: 26px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #talentTop .talent .snsList .sns p {
    margin-left: 1.90337vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #talentTop .talent .snsList .sns p {
    margin-left: 1.90337vw;
  }
}

#talentTop .talent .btn02 {
  margin: 0 auto;
}
