@charset "utf-8";
/* ==============================================
トップページ
============================================== */
#indexTop{
  background-color: #41603a;
  @include props(pt, 80);

  @include mq(md) {
    @include props(pt, 80, 750);
  }

  .mainview{
    z-index: 1;
    .video{
      position: absolute;
      top: 0;
      left: 0;
      max-width: 100%;
      width: 100%;
      padding-bottom: 56.25%;
      z-index: -1;
      overflow: hidden;
      background-image: url(/images/video/fv_video@2x.png);
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      
      @include mq(md) {
        padding-bottom: 117.334%;
        background-image: url(/images/video/fv_video_sp@2x.png);
      }
  
      img{
        width: 100%;
      }
      iframe,
      video{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        //opacity: 0;
        transition: opacity .3s ease-out;

        &[data-ready="true"]{
          opacity: 1;
        }

        @include mq(md) {
          //width: 253vw;
          width: auto;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }

    &::after{
      content: "";
      display: block;
      width: 100%;
      padding-bottom: 56.25%;

      @include mq(md) {
        padding-bottom: 117.334%;
      }
    }
  }

  .talentArea{
    @include props(pt, 92);
    @include props(pb, 108);

    @include mq(md) {
      @include props(pt, 95, 750);
      @include props(pb, 100, 750);
      @include props(pr, 40, 750);
      @include props(pl, 40, 750);
    }

    .talentAreaLead{
      @include fz_vw(20);
      font-weight: 300;
      line-height: 2.4;
      letter-spacing: 1px;
      color: #fff;
      @include props(mb, 105);

      @include mq(md) {
        @include fz_vw(28);
        line-height: 2;
        letter-spacing: .7px;
        @include props(mb, 52, 750);
      }
    }
  }

  .talentListArea{
    @include props(pt, 105);

    @include mq(md) {
      @include props(pt, 52, 750);
    }

    .talentListAreaTit{
      font-family: $Oswald;
      @include fz_vw(50);
      font-weight: 500;
      line-height: 1;
      letter-spacing: 3.5px;
      text-align: center;
      color: #fff;
      @include props(mb, 110);

      @include mq(md) {
        @include fz_vw(52);
        letter-spacing: 1.82px;
        @include props(mb, 85, 750);
      }
    }

    .flex{
      display: flex;
      justify-content: space-between;

      @include mq(md) {
        display: block;
      }

      .talentCard{
        max-width: 26.067%;
        width: 100%;

        @include mq(md) {
          max-width: 100%;
          @include props(mb, 105, 750);

          &:last-of-type{
            margin-bottom: 0
          }
        }

        a{
          display: block;
          @include hoverImg;

          &:hover{
            .moreBtn{
              p{
                color: #fff;
                background-color: #295829;
                &::after{
                  background-image: url(/images/common/arrow_white.svg);
                  transform: translateX(5px);

                  &:hover{
                    background-image: url(/images/common/arrow_white_sp.svg);
                  }
                }
              }
            }
          }
        }
        .img{
          width: 100%;
          overflow: hidden;
          @include props(h, 440);
          @include props(mb, 30);

          @include mq(md) {
            @include props(h, 786, 750);
            @include props(mb, 45, 750);
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .job{
          @include fz_vw(16);
          line-height: 1.5;
          letter-spacing: 1.2px;
          color: #fff;
          @include props(mb, 10);

          @include mq(md) {
            @include fz_vw(26);
            line-height: 1.23;
            letter-spacing: .875px;
            @include props(mb, 20, 750);
          }
        }
        .name{
          @include fz_vw(24);
          font-weight: 500;
          line-height: 2;
          letter-spacing: 2.4px;
          color: #fff;
          @include props(mb, 55);

          .furigana{
            display: block;
            font-family: $Oswald;
            @include fz_vw(16);
            font-weight: 500;
            line-height: 1;
            letter-spacing: 1.6px;
            color: #fff;
            text-transform: uppercase;
            @include props(mt, 13);
          }

          @include mq(md) {
            @include fz_vw(36);
            line-height: 1.33;
            letter-spacing: 1.8px;
            @include props(mb, 45, 750);

            .furigana{
              @include fz_vw(28);
              letter-spacing: 1.4px;
              @include props(mt, 20, 750);
            }
          }
        }
        .moreBtn{
          @include props(w, 122);
          margin: 0 0 0 auto;

          @include mq(md) {
            @include props(w, 240, 750);
          }

          p{
            display: flex;
            align-items: baseline;
            justify-content: center;
            font-family: $Oswald;
            @include fz_vw(17);
            font-weight: 500;
            line-height: 1;
            letter-spacing: 1.7px;
            color: #295829;
            background-color: #fff;
            border: solid 1px #fff;
            border-radius: 50px;
            transition: all .3s ease-out;
            @include props(pt, 9);
            @include props(pb, 9);
      
            &::after{
              content: "";
              display: block;
              flex-shrink: 0;
              background-image: url(/images/common/arrow_green.svg);
              background-position: top center;
              background-repeat: no-repeat;
              background-size: contain;
              transition: transform .3s ease-out;
              @include props(w, 18);
              @include props(h, 7);
              @include props(mb, 2);
              @include props(ml, 7);
            }

            @include mq(md) {
              @include fz_vw(32);
              @include props(pt, 20, 750);
              @include props(pb, 20, 750);
      
              &::after{
                background-image: url(/images/common/arrow_green_sp.svg);
                @include props(w, 34, 750);
                @include props(h, 8, 750);
                background-position: bottom 1px center;
                @include props(mb, 2, 750);
                @include props(ml, 15, 750);
              }
            }
          }
        }
      }
    }
  }

  .pickUpArea{
    @include props(pt, 108);
    @include props(pb, 95);

    @include mq(md) {
      @include props(pt, 100, 750);
      @include props(pb, 100, 750);
    }
    
    .pickUpAreaTit{
      font-family: $Oswald;
      @include fz_vw(50);
      font-weight: 500;
      line-height: 1;
      letter-spacing: 3.5px;
      text-align: center;
      color: #fff;
      @include props(mb, 110);

      @include mq(md) {
        @include fz_vw(52);
        letter-spacing: 1.82px;
        @include props(mb, 95, 750);
      }
    }

    .ps--active-y{
      .ps__thumb-y {
        display: block;
        background-color: #41603a;
        border-radius: 0;
        @include props(w, 14);
        @include props(r, 9);
  
        @include mq(md) {
          display: none;
        }
      }
      .ps__rail-y{
        display: block;
        @include props(w, 32);
        @include props(mr, 14);
        opacity: 1;
        background-color: #95b291;
  
        &::before{
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          @include props(h, 10);
          background-color: #95b291;
          z-index: 1;
        }
        &::after{
          content: "";
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          @include props(h, 10);
          background-color: #95b291;
        }
  
        @include mq(md) {
          display: none;
        }
      }
    }

    .newsListBox{
      border-radius: 10px;
      background-color: #fff;
      @include props(pt, 32);
      @include props(pb, 32);

      @include mq(md) {
        border-radius: 0;
        @include props(pt, 0);
        @include props(pb, 0);
      }
    }

    .newsList{
      position: relative;
      overflow-y: scroll;
      @include props(pl, 50);
      @include props(pr, 115);
      @include props(h, 436);

      @include mq(md) {
        @include props(pt, 45, 750);
        @include props(pb, 35, 750);
        @include props(pl, 40, 750);
        @include props(pr, 40, 750);
        @include props(h, 643, 750);
      }

      .newsListCon{

        a{
          display: flex;
          align-items: center;
          border-bottom: solid 1px #386732;
          transition: opacity .3s ease-out;
          @include props(mh, 109);
          @include props(pt, 10);
          @include props(pb, 10);

          &:hover{
            opacity: .7;
          }

          .newsListTit{
            text-decoration: underline;
          }

          @include mq(md) {
            flex-flow: column;
            justify-content: center;
            max-height: none;
            height: 100%;
            @include props(mh, 135 ,750);
            @include props(pt, 0);
            @include props(pb, 15, 750);
            @include props(mb, 25, 750);
          }
        }
        .notLink{
          display: flex;
          align-items: center;
          border-bottom: solid 1px #386732;
          transition: opacity .3s ease-out;
          @include props(mh, 109);
          @include props(pt, 10);
          @include props(pb, 10);

          @include mq(md) {
            flex-flow: column;
            justify-content: center;
            max-height: none;
            height: 100%;
            @include props(mh, 135 ,750);
            @include props(pt, 0);
            @include props(pb, 15, 750);
            @include props(mb, 25, 750);
          }
        }
        .newsListTime{
          max-width: 13.287%;
          width: 100%;
          display: block;
          @include fz_vw(16);
          font-weight: 600;
          line-height: 2;
          letter-spacing: 0.8px;
          color: #41603a;
          
          @include mq(md) {
            max-width: 100%;
            @include fz_vw(26);
            line-height: 1.23;
            letter-spacing: .65px;
            @include props(mb, 10, 750);
          }
        }
        .newsListTit{
          max-width: 86.713%;
          width: 100%;
          @include fz_vw(16);
          line-height: 2;
          letter-spacing: 0.8px;
          color: #41603a;
          
          @include mq(md) {
            max-width: 100%;
            @include fz_vw(28);
            letter-spacing: .7px;
          }
        }
      }
    }
  }

  .companyInfoArea{
    @include props(pt, 95);
    @include props(pb, 155);

    @include mq(md) {
      @include props(pt, 100, 750);
      @include props(pb, 305, 750);
    }

    .companyInfoAreaTit{
      font-family: $Oswald;
      @include fz_vw(50);
      font-weight: 500;
      line-height: 1;
      letter-spacing: 3.5px;
      text-align: center;
      color: #fff;
      @include props(mb, 75);

      @include mq(md) {
        @include fz_vw(52);
        letter-spacing: 1.82px;
        @include props(mb, 85, 750);
      }
    }
    .companyInfoAreaTxtBox{
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      @include props(mb, 40);

      .linkTxt01{
        flex-shrink: 0;
      }

      @include mq(md) {
        display: block;
        @include props(pr, 40, 750);
        @include props(pl, 40, 750);
        @include props(mb, 175, 750);
      }
    }
    .companyInfoAreaTxt{
      @include fz_vw(16);
      font-weight: 300;
      line-height: 2;
      letter-spacing: 0.8px;
      color: #fff;

      @include mq(md) {
        @include fz_vw(28);
        letter-spacing: .7px;
      }
    }

    .linkTxt01{
      @include mq(md) {
        @include props(mt, 50, 750);
      }
    }

    .ShibaEntarteimentArea{
      @include props(mt, 112);
      @include props(mb, 120);

      @include mq(md) {
        @include props(pr, 40, 750);
        @include props(pl, 40, 750);
        @include props(mt, 90, 750);
        @include props(mb, 110, 750);
      }

      .ShibaEntarteimentAreaTit{
        @include fz_vw(24);
        font-weight: 500;
        line-height: 1.6;
        letter-spacing: 1.2px;
        color: #fff;
        @include props(mb, 15);

        @include mq(md) {
          @include fz_vw(32);
          line-height: 1.5;
          letter-spacing: .8px;
          @include props(mb, 80, 750);
        }
      }
      .ShibaEntarteimentAreaTxt{
        @include fz_vw(16);
        font-weight: 300;
        line-height: 2;
        letter-spacing: 0.8px;
        color: #fff;
        @include props(mb, 28);

        &:last-of-type{
          margin-bottom: 0;
        }

        @include mq(md) {
          @include fz_vw(26);
          letter-spacing: .65px;
          @include props(mb, 52, 750);
        }
      }
      .map{
        position: relative;
        max-width: 100%;
        width: 100%;
        @include props(h, 365);

        @include mq(md) {
          @include props(h, 390, 750);
          @include props(mt, 80, 750);
        }
        
        iframe{
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }

      .flex{
        display: flex;
        justify-content: space-between;
        @include props(mb, 95);

        &:last-of-type{
          margin-bottom: 0;
        }

        @include mq(md) {
          flex-flow: column;
          @include props(mb, 110, 750);
        }

        .leftBox{
          max-width: 47.342%;
          width: 100%;

          @include mq(md) {
            max-width: 100%;
            order: 2;
          }
        }
        .rightBox{
          max-width: 52.658%;
          width: 100%;
          @include props(pl, 60);

          @include mq(md) {
            max-width: 100%;
            order: 1;
            @include props(pl, 0);
          }
        }
      }
    }

    .btn01{
      margin: 0 auto;
    }
  }
}